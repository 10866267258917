import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { signOut } from "aws-amplify/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import { User } from "models";

export const navItems = [
  { name: "Home", to: "/", icon: HomeIcon, subPaths: ["/my-bets", "/popular"] },
  { name: "Bet", to: "/bet", icon: EditIcon },
  { name: "Search", to: "/search", icon: PersonSearchIcon },
  // {
  //   name: "Notifications",
  //   to: "/notifications",
  //   icon: NotificationsActiveIcon,
  // },
  { name: "Profile", to: "/profile", icon: AccountCircleIcon },
  // { name: "Settings", to: "/settings", icon: SettingsIcon },
];

export const SignOutButton = ({ fetchedUser }: { fetchedUser: any }) => {
  return fetchedUser?.id ? (
    <button
      onClick={() => signOut()}
      className="text-white bg-red-600 text-md py-2 px-4 rounded flex items-center flex-col hover:bg-red-700 transition-colors duration-300"
    >
      <LogoutIcon className="mr-2 " />
      <p>Sign Out</p>
    </button>
  ) : (
    <> </>
  );
}