import { Button, Dialog } from "@material-tailwind/react";
import { useAuthContext } from "context/AuthContext";
import React, { useState } from "react";
import { updateUser } from "services/UserService";
import { formatPrice } from "utils/format";

interface TailFeeModalProps {
  isOpen: boolean;
  onClose: () => void;
  callBackFunction?: (data: any) => void;
  originalTailFee: number;
}

const TailFeeModal = ({
  isOpen,
  onClose,
  callBackFunction,
  originalTailFee,
}: TailFeeModalProps) => {
  const { fetchedUser } = useAuthContext();
  const [tailFee, setTailFee] = useState<string>(formatPrice(originalTailFee));

  const handleTailFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setTailFee(inputValue ? (parseInt(inputValue) / 100).toFixed(2) : "");
  };

  const handleSubmit = async () => {
    if (!fetchedUser?.id) return;
    if (originalTailFee === parseFloat(tailFee) * 100) {
      onClose();
      return;
    }
    if (callBackFunction) {
      callBackFunction(parseFloat(tailFee) * 100);
    }
    await updateUser({
      id: fetchedUser?.id,
      subscriptionCost: parseFloat(tailFee) * 100,
    });
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      handler={onClose}
      size="lg"
      className="justify-center items-center max-w-lg w-full p-8 bg-black bg-opacity-50"
    >
      <div className="p-8 rounded-lg text-white">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-center">Set Tail Fee</h2>
          <p className="text-sm my-4">
            *Tail Fee is the amount other cappers will have to pay to gain
            access to your PRIVATE bets.
          </p>
          <p className="text-sm my-4">
            You may set it at any amount you would like.
          </p>
        </div>

        <div className="text-3xl lg:text-6xl text-center font-bold my-4">
          ${tailFee}/mo
        </div>
        <input
          type="text"
          value={tailFee}
          onChange={handleTailFeeChange}
          className="w-full p-2 text-center border-2 border-black rounded text-black"
        />
        <div className="flex justify-center">
          <Button
            onClick={handleSubmit}
            className="bg-dark-primary-gradient text-white text-lg px-6 py-2 mt-8 shadow-md shadow-black border-2 border-white"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default TailFeeModal;
