import React from "react";
import { Typography, Card } from "@material-tailwind/react";
import { SvgIconComponent } from "@mui/icons-material";

interface FeatureCardProps {
  Icon: SvgIconComponent;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  Icon,
  title,
  description,
}) => {
  return (
    <Card className="w-full md:w-1/4 p-6 m-4 bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105">
      <Icon className="h-12 w-12 mb-4 mx-auto text-dark-orange" />
      <Typography variant="h5" color="gray" className="font-bold mb-2">
        {title}
      </Typography>
      <Typography color="gray">{description}</Typography>
    </Card>
  );
};

export default FeatureCard;
