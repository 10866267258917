import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  IconButton,
} from "@material-tailwind/react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Schema } from "../../../amplify/data/resource";
import { formatExtendedUSDate } from "utils/format";
import { calculateToWin, calculateTotalOdds } from "utils/helper/Calculate";
import EditLegModal from "./EditLegModal";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { createBet } from "services/BetService";
import { useAuthContext } from "context/AuthContext";

interface BetSlip {
  ISOdateTime: string;
  type: string;
  legs: Schema["Bet"]["type"]["legs"];
  ISOdateTIme: Schema["Bet"]["type"]["ISOdateTime"];
  total_odds: number;
}

interface BetSlipModalProps {
  isOpen: boolean;
  onClose: () => void;
  betSlip: BetSlip;
}

const BetSlipModal: React.FC<BetSlipModalProps> = ({
  isOpen,
  onClose,
  betSlip,
}) => {
  const [wager, setWager] = useState<number>(1);
  const [toWin, setToWin] = useState<number>(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [legs, setLegs] = useState(betSlip.legs);
  const [totalOdds, setTotalOdds] = useState(betSlip.total_odds);
  const [editingLeg, setEditingLeg] = useState<
    Schema["Bet"]["type"]["legs"][0] | null
  >(null);
  const [showEditLegModal, setShowEditLegModal] = useState(false);
  const [isPrivate, setIsPrivate] = useState<boolean>(true);
  const { fetchedUser } = useAuthContext();

  useEffect(() => {
    setToWin(calculateToWin(wager, totalOdds));
  }, [wager, totalOdds]);

  useEffect(() => {
    setTotalOdds(calculateTotalOdds(legs));
  }, [legs]);

  // Reset modal state when it is opened
  useEffect(() => {
    if (isOpen) {
      setWager(1);
      setToWin(calculateToWin(1, betSlip.total_odds));
      setLegs(betSlip.legs);
      setTotalOdds(betSlip.total_odds);
      setEditingLeg(null);
      setShowEditLegModal(false);
      setIsPrivate(true);
    }
  }, [isOpen, betSlip]);

  const handleEditLeg = (legIndex: number) => {
    setEditingLeg(legs[legIndex]);
    setShowEditLegModal(true);
  };

  const handleSaveLeg = (updatedLeg: Schema["Bet"]["type"]["legs"][0]) => {
    const updatedLegs = legs.map((leg, index) =>
      index === legs.findIndex((l) => l === editingLeg) ? updatedLeg : leg
    );
    setLegs(updatedLegs);
    setShowEditLegModal(false);
  };

  const handleRemoveLeg = (legIndex: number) => {
    const updatedLegs = legs.filter((_, index) => index !== legIndex);
    setLegs(updatedLegs);
  };

  const handleClose = () => {
    setShowConfirmDialog(true);
  };

  const confirmClose = () => {
    setShowConfirmDialog(false);
    // reset our data
    setLegs([null]);
    setTotalOdds(0);
    onClose();
  };

  const cancelClose = () => {
    setShowConfirmDialog(false);
  };

  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivate(event.target.value === "private");
  };

  const handleSaveBet = async () => {
    if (!fetchedUser?.id) return;
    const createdBet = await createBet({
      combinedOdds: totalOdds,
      isActive: true,
      isPrivate: isPrivate,
      legs: legs,
      result: "PENDING",
      type: legs.length > 1 ? "PARLAY" : "SINGLE",
      userId: fetchedUser.id,
      wager: wager,
      ISOdateTime: betSlip.ISOdateTime,
    });
    // reset our data
    setLegs([null]);
    setTotalOdds(0);
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        handler={() => {}}
        size="lg"
        className="bg-black rounded-lg shadow-primary shadow-xl"
      >
        <DialogHeader className="flex justify-between items-center text-white">
          <h6 className="text-lg">Betslip</h6>
          <span className="font-bold text-dark-orange">
            {totalOdds > 0 ? "+" + totalOdds : totalOdds}
          </span>
          <IconButton className="text-white" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogHeader>

        <DialogBody className="text-white max-h-[70vh] pb-20 overflow-y-auto">
          <div className="flex flex-col space-y-2 mt-4">
            <span className="font-bold">Bet Privacy:</span>
            <div className="flex justify-center items-center space-x-4">
              <RadioGroup
                aria-labelledby="bet-privacy-label"
                name="bet-privacy-group"
                value={isPrivate ? "private" : "public"}
                onChange={handlePrivacyChange}
                row
              >
                <FormControlLabel
                  value="public"
                  control={<Radio />}
                  className="text-white"
                  label="Public - anyone can view"
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  className="text-white"
                  label="Private - only my subscribers"
                />
              </RadioGroup>
            </div>
          </div>
          {legs.map((leg, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-selection-gradient my-4 p-2 rounded-md"
            >
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                <img
                  src={"https://picsum.photos/200"} // Replace with the actual path to the team logo
                  alt="Team Logo"
                  className="w-8 h-8 mr-4 rounded-full"
                />
                <div className="flex flex-col">
                  <span className="font-bold">
                    {leg?.entity === "TEAM"
                      ? leg?.team?.name
                      : leg?.player?.name}
                    <span>
                      {" "}
                      {leg?.odds && leg.odds > 0
                        ? "+" + Math.abs(leg?.odds || 0)
                        : -Math.abs(leg?.odds || 0)}
                    </span>
                  </span>
                  <span>{leg?.type.toUpperCase()}</span>
                  <span>{leg?.league}</span>
                  <div className="flex flex-col">
                    <span>
                      {leg?.date
                        ? formatExtendedUSDate(leg?.date)
                        : "No date found."}
                      {" - "}
                      {leg?.time}
                    </span>
                  </div>
                  <span>{leg?.event}</span>
                </div>
              </div>
              <div className="flex space-x-2">
                <Button
                  size="sm"
                  className="bg-dark-orange text-white"
                  onClick={() => handleEditLeg(index)}
                >
                  <EditIcon />
                </Button>
                <Button
                  size="sm"
                  color="red"
                  onClick={() => handleRemoveLeg(index)}
                >
                  ✕
                </Button>
              </div>
            </div>
          ))}
          <div className="flex flex-col sm:flex-row justify-between items-center my-4 space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="flex flex-col space-y-1">
              <p className="font-bold">Wager:</p>
              <input
                value={wager}
                type="number"
                onChange={(e) => setWager(parseFloat(e.target.value))}
                className="text-black px-2 rounded-sm shadow-primary"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <p className="font-bold">To Win:</p>
              <input
                value={(isNaN(toWin) ? 0 : toWin.toFixed(2)) + "U"}
                disabled
                className="px-2 rounded-sm"
              />
            </div>
          </div>
          <p className="text-xs mt-2 text-center">
            *Once a bet has been confirmed it will be posted and permanently
            added to your bettor history and factored into your bettor
            performance and earnings insights
          </p>
        </DialogBody>

        <DialogFooter className="flex justify-center">
          <Button
            onClick={handleSaveBet}
            className="bg-dark-orange text-white w-full "
          >
            Confirm Bet
          </Button>
        </DialogFooter>
      </Dialog>

      <EditLegModal
        isOpen={showEditLegModal}
        leg={editingLeg}
        onSave={handleSaveLeg}
        onClose={() => setShowEditLegModal(false)}
      />

      {showConfirmDialog && (
        <Dialog
          open={showConfirmDialog}
          handler={() => {}}
          size="sm"
          className="bg-black"
        >
          <DialogHeader className="flex justify-between items-center text-white">
            <h6 className="text-lg">Confirm Close</h6>
            <IconButton className="text-white" onClick={cancelClose}>
              <CloseIcon />
            </IconButton>
          </DialogHeader>

          <DialogBody className="text-white">
            <p className="text-center">
              Are you sure you want to close the bet slip? Your bet data will
              not be saved.
            </p>
          </DialogBody>

          <DialogFooter className="flex justify-center ">
            <div className="flex flex-row space-x-12">
              <Button
                onClick={confirmClose}
                className="bg-red-600 text-white w-full mr-2"
              >
                Discard this bet
              </Button>
              <Button
                onClick={cancelClose}
                className="bg-dark-orange text-white w-full"
              >
                Keep Editing
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};

export default BetSlipModal;
