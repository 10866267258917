// LoadingScreen.tsx
import React, { useEffect, useState } from "react";
import SportsBasketballIcon from "@mui/icons-material/DownhillSkiing";
import SportsFootballIcon from "@mui/icons-material/DownhillSkiing";
import SportsHockeyIcon from "@mui/icons-material/DownhillSkiing";
import SportsBaseballIcon from "@mui/icons-material/DownhillSkiing";
import SportsSoccerIcon from "@mui/icons-material/DownhillSkiing";
import colors from "theme/colors";

const iconCommentPairs = [
  {
    icon: SportsBasketballIcon,
    comment: "Did you know? A football field is 100 yards long!",
  },
  {
    icon: SportsFootballIcon,
    comment: "Pro tip: Always bet on the underdog... just kidding!",
  },
  {
    icon: SportsHockeyIcon,
    comment: "Fun fact: Basketball was invented by a Canadian!",
  },
  {
    icon: SportsBaseballIcon,
    comment: "Remember: The house always wins, but you can still have fun!",
  },
  {
    icon: SportsSoccerIcon,
    comment: "Soccer players run an average of 7 miles per game!",
  },
];

const LoadingScreen: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % iconCommentPairs.length);
    }, 3000); // Adjust the interval time as needed

    return () => clearInterval(interval);
  }, []);

  const { icon: CurrentIcon, comment: currentComment } =
    iconCommentPairs[currentIndex];

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-modal-black">
      <div className="border-4 border-lightgrey border-t-primary rounded-full w-16 h-16 mb-5 animate-spin"></div>
      <div className="mb-5">
        <CurrentIcon style={{ fontSize: 60, color: colors["mid-orange"] }} />
      </div>
      <h1 className="text-white text-2xl font-light tracking-widest">
        Loading...
      </h1>
      <p className="text-grey text-center mt-3 px-5">{currentComment}</p>
    </div>
  );
};

export default LoadingScreen;
