import { Line } from "react-chartjs-2";
// Ensure that Chart.js modules are registered if needed
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const LineChart: React.FC<{
  chartData: ChartData<"line", number[], string>;
}> = ({ chartData }) => {
  return <Line data={chartData} />;
};
