import { generateClient } from "aws-amplify/api";
import { Schema } from "../../amplify/data/resource";

const client = generateClient<Schema>();

export const createBet = async (input: {
  type: Schema["Bet"]["type"]["type"];
  result: Schema["Bet"]["type"]["result"];
  combinedOdds: Schema["Bet"]["type"]["combinedOdds"];
  wager: Schema["Bet"]["type"]["wager"];
  isActive: Schema["Bet"]["type"]["isActive"];
  isPrivate: Schema["Bet"]["type"]["isPrivate"];
  userId: Schema["Bet"]["type"]["userId"];
  legs: Schema["Bet"]["type"]["legs"];
  ISOdateTime: Schema["Bet"]["type"]["ISOdateTime"];
}) => {
  try {
    const { data, errors } = await client.models.Bet.create({
      type: input.type,
      result: input.result ?? "PENDING",
      combinedOdds: input.combinedOdds,
      wager: input.wager,
      isActive: input.isActive,
      isPrivate: input.isPrivate,
      userId: input.userId,
      legs: input.legs,
      ISOdateTime: input.ISOdateTime,
    });
    if (errors) throw new Error(JSON.stringify(errors));
  } catch (err: any) {
    console.warn("error: Betservice createBet", err);
  }
};

export const getBetByID = async (betId: string) => {
  try {
    const { data, errors } = await client.models.Bet.get({
      id: betId,
    });
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: Betservice getBetsByID", err);
  }
};

export const getBetByUserIdWithUser = async (userId: string) => {
  try {
    const { data: fetchedUser, errors: fetchUserErrors } =
      await client.models.User.get({
        id: userId,
      });
    const { data: fetchedBets, errors: fetchBetErrors } =
      await client.models.Bet.listBetByUserId({
        userId,
      });
    if (fetchUserErrors || fetchBetErrors)
      throw new Error(
        JSON.stringify(fetchUserErrors) + " " + JSON.stringify(fetchBetErrors)
      );
    // Flatten the data structure here rather than returning a nested array
    return fetchedBets?.map((bet) => ({ bet, user: fetchedUser }));
  } catch (err) {
    console.warn("error: Betservice getBetByUserIdWithUser", err);
    return []; // Ensure to return an empty array in case of an error
  }
};

export const fetchAllSubscribedBets = async (userId: string) => {
  //fetch the bets of the users the curently authenticated user is subscribed to
  // const subscribedUsers = await DataStore.query(User, (u) => u.s)
  try {
    const { data, errors } =
      await client.models.UserFollowing.listUserFollowingBySubscriberId(
        {
          subscriberId: userId,
        },
        { selectionSet: ["Provider.Bets.*", "Provider.*"] }
      );
    if (errors) throw new Error(JSON.stringify(errors));
    const providerBets = data?.map((data) => ({
      bet: data.Provider.Bets,
      user: data.Provider,
    }));
    // return providerBets;
  } catch (err: any) {
    console.warn("error: Betservice fetchAllSubscribedBets", err);
  }
};

export const fetchAllUserBets = async (userId: string) => {
  try {
    const [
      { data: fetchedBets, errors: fetchBetErrors },
      { data: fetchedUser, errors: fetchUserErrors },
    ] = await Promise.all([
      client.models.Bet.listBetByUserId({
        userId,
      }),
      client.models.User.get({
        id: userId,
      }),
    ]);
    if (fetchBetErrors || fetchUserErrors)
      throw new Error(
        JSON.stringify(fetchBetErrors) + " " + JSON.stringify(fetchUserErrors)
      );
    const result = fetchedBets?.map((bet) => ({ bet, user: fetchedUser }));
    return result;
  } catch (err) {
    console.warn("error: Betservice fetchAllUserBets", err);
  }
};

export const fetchUserBetsWithFilters = async (
  userId: string,
  isActive: boolean,
  isSubscribed?: boolean,
  sortPopular?: boolean
) => {
  try {
    const { data: fetchedBets, errors: fetchBetErrors } =
      await client.models.Bet.list({
        filter: {
          userId: { eq: userId },
          isActive: { eq: isActive },
        },
        // sort: sortPopular
        //   ? [{ field: "tailedCount", direction: "DESC" }]
        //   : undefined,
      });
    const { data: fetchedUser, errors: fetchUserErrors } =
      await client.models.User.get({
        id: userId,
      });

    if (fetchBetErrors || fetchUserErrors)
      throw new Error(
        JSON.stringify(fetchBetErrors) + " " + JSON.stringify(fetchUserErrors)
      );

    const fetchedBetsAndUser: {
      bet: Schema["Bet"]["type"];
      user: Schema["User"]["type"];
    }[] = fetchedBets?.map((bet) => ({
      bet,
      user: fetchedUser!,
    }));
    return fetchedBetsAndUser;
  } catch (err) {
    console.warn("error: Betservice fetchUserBets", err);
    return [];
  }
};

export const fetchPopularBetsWithUser = async () => {
  try {
    const { data: fetchedBets, errors: fetchBetErrors } =
      await client.models.Bet.list({
        filter: {
          isActive: { eq: true },
          isPrivate: { eq: false },
        },
        // sort: [{ field: "createdAt", direction: "DESC" }],
      });

    if (fetchBetErrors) throw new Error(JSON.stringify(fetchBetErrors));

    const betsWithDetails = await Promise.all(
      fetchedBets.map(async (bet) => {
        const { data: betUser, errors: fetchUserErrors } =
          await client.models.User.get({
            id: bet.userId,
          });

        const { data: tailedBets, errors: fetchTailedBetErrors } =
          await client.models.TailedBet.list({
            filter: { betId: { eq: bet.id } },
          });

        if (fetchUserErrors || fetchTailedBetErrors)
          throw new Error(
            JSON.stringify(fetchUserErrors) +
              " " +
              JSON.stringify(fetchTailedBetErrors)
          );

        return {
          bet,
          user: betUser,
          tailedCount: tailedBets.length,
        };
      })
    );

    const sortedBets = betsWithDetails.sort(
      (a, b) => b.tailedCount - a.tailedCount
    );
    return sortedBets;
  } catch (err) {
    console.warn("error: Betservice fetchPopularBetsWithUser", err);
    return [];
  }
};

export const fetchUserBetsSince = async (userId: string, fromDate: Date) => {
  try {
    const fromDateString = fromDate.toISOString(); // Convert date to ISO string for comparison
    const [
      { data: fetchedBets, errors: fetchBetErrors },
      { data: fetchedUser, errors: fetchUserErrors },
    ] = await Promise.all([
      client.models.Bet.list({
        filter: {
          userId: { eq: userId },
          createdAt: { ge: fromDateString },
        },
      }),
      client.models.User.get({
        id: userId,
      }),
    ]);

    if (fetchUserErrors || fetchBetErrors)
      throw new Error(
        JSON.stringify(fetchUserErrors) + " " + JSON.stringify(fetchBetErrors)
      );

    // Fetch user data to include with each bet
    const fetchedBetsAndUser = fetchedBets.map((bet) => {
      return { bet, user: fetchedUser };
    });

    return fetchedBetsAndUser;
  } catch (err) {
    console.warn("Error in fetchUserBetsSince:", err);
    return [];
  }
};

export const fetchAllBets = async () => {
  try {
    const { data, errors } = await client.models.Bet.list();
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: Betservice fetchAllBets", err);
    return [];
  }
};

/**
 *
 * @returns Bets sorted with Active bets at the top
 */
export const fetchAllBetsWithUser = async () => {
  try {
    // TODO: figure out how to filter a list operation
    const { data, errors } = await client.models.Bet.list({});

    // Need the b.Wager.ne(0) to be able to sort
    // const fetchedBets = await DataStore.query(Bet, Predicates.ALL, {
    //   sort: (b) => b.isActive(SortDirection.DESCENDING),
    // });
    // Map over fetched bets to get users for each bet asynchronously
    const fetchedBetsAndUsers = await Promise.all(
      data.map(async (bet) => {
        const betUser = await bet.User(); // Assuming each 'bet' has 'userId' to find its corresponding user
        return { bet, user: betUser };
      })
    );
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: Betservice fetchBetsWithUser", err);
  }
};

export const fetchBetsData = async () => {};

export const processBetSlip = async (input: { data: string[] }) => {
  try {
    const { data, errors } = await client.queries.ProcessBetSlip({
      s3URIs: input.data,
    });
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: Betservice processBetSlip", err);
    return;
  }
};
