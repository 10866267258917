import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import BetCard from "components/cards/BetCard";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { fetchUserBetsWithFilters } from "services/BetService";
import { formatExtendedUSDate, formatPrice } from "utils/format";
import { getUserByID } from "services/UserService";
import { Button, Alert } from "@material-tailwind/react";
import {
  serviceCreateUserFollowing,
  serviceUnsubscribeUser,
} from "services/UserFollowingService";
import { useAuthContext } from "context/AuthContext";
import { Schema } from "../../../amplify/data/resource";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

function CapperScreen() {
  const { id } = useParams<{ id: string }>();
  const { fetchedUser } = useAuthContext();
  const location = useLocation();
  const [fetchedBets, setFetchedBets] =
    useState<{ bet: Schema["Bet"]["type"]; user?: Schema["User"]["type"] }[]>();
  const [fetchedCapper, setFetchedCapper] = useState<Schema["User"]["type"]>();
  const [userSubscribers, setUserSubscribers] = useState<any[]>();
  const [userSubscribedTo, setUserSubscribedTo] = useState<any[]>();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [chartData, setChartData] = useState<ChartData<"line"> | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<"success" | "error">("success");

  useEffect(() => {
    if (!id) return;
    fetchSearchedUser();
  }, [id]);

  useEffect(() => {
    getDataForRoute();
  }, [location.pathname]);

  useEffect(() => {
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "",
          data: [65, 59, 80, 81, 56, 55, 65],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.05,
        },
      ],
    };
    setChartData(data);
  }, []);

  return (
    <div className="px-4 lg:px-14 my-8 bg-modal-black bg-opacity-75">
      {alertMessage && (
        <Alert
          color={getAlertColor()}
          onClose={handleAlertClose}
          className="mb-4"
        >
          {alertMessage}
        </Alert>
      )}
      <CapperHeader />
      <div className="flex flex-row space-x-4 place-content-evenly px-2 pt-4 lg:px-5">
        <NavLink
          to={`/search/${id}`}
          className={`text-white hover:text-gray-700 ${getNavLinkClass(
            `/search/${id}`
          )}`}
        >
          Active Bets
        </NavLink>
        <NavLink
          to={`/search/${id}/settled-bets`}
          className={`text-white hover:text-gray-700 ${getNavLinkClass(
            `/search/${id}/settled-bets`
          )}`}
        >
          Settled Bets
        </NavLink>
        <NavLink
          to={`/search/${id}/earnings`}
          className={`text-white hover:text-gray-700 ${getNavLinkClass(
            `/search/${id}/earnings`
          )}`}
        >
          Earnings
        </NavLink>
      </div>
      {getPageForRoute()}
    </div>
  );

  function getNavLinkClass(path: string) {
    return location.pathname === path
      ? "text-white underline decoration-white decoration-2"
      : "text-white hover:underline";
  }

  // TODO: make this function work properly
  async function getDataForRoute() {
    let bets;
    if (!id) return;
    switch (location.pathname) {
      case `/search/${id}/settled-bets`:
        bets = await fetchUserBetsWithFilters(id, false, false);
        break;
      case `/popular`:
        bets = await fetchUserBetsWithFilters(id, false, false);
        break;
      default:
        bets = await fetchUserBetsWithFilters(id, true, false);
        break;
    }
    setFetchedBets(bets);
  }

  function getPageForRoute() {
    switch (location.pathname) {
      case `/search/${id}/earnings`:
        if (!chartData) return null;
        return (
          <div className="my-12">
            <h1 className="self-center text-center text-white font-bold">
              Earnings tracking coming soon!
            </h1>
          </div>
        );
      default:
        return (
          <div className="my-2 lg:my-8 h-[calc(92vh-200px)] pb-16 overflow-auto">
            {fetchedBets?.map(({ bet, user }) => (
              <div className="my-2 lg:my-8 mx-4" key={bet.id}>
                <BetCard
                  betID={bet.id}
                  userID={bet.userId}
                  username={user?.username ?? ""}
                  handle={user?.handle!}
                  betDate={formatExtendedUSDate(bet.createdAt ?? "")}
                  profilePictureURI={user?.profilePicURI}
                  odds={bet.combinedOdds}
                  wager={bet.wager}
                  result={bet.result}
                  legs={bet.legs}
                  active={bet.isActive!}
                  tailing={false}
                />
              </div>
            ))}
          </div>
        );
    }
  }

  function handleAlertClose() {
    setAlertMessage(null);
  }

  function getAlertColor() {
    switch (alertType) {
      case "success":
        return "green";
      default:
        return "red";
    }
  }

  async function handleSubscribe() {
    try {
      if (!fetchedUser?.id) throw new Error("User not signed in");
      if (!fetchedCapper?.id) throw new Error("Capper not found");
      await serviceCreateUserFollowing({
        subscriberID: fetchedUser?.id,
        providerID: fetchedCapper?.id,
        subscriptionRate: fetchedCapper?.subscriptionCost ?? 0,
        subscriptionPlan: "MONTHLY",
      });
      setAlertType("success");
      setAlertMessage("Subscription successful!");
      setIsSubscribed(true);
    } catch (error: any) {
      setAlertType("error");
      setAlertMessage(
        `Failed to subscribe: ${error?.message ?? "please try again."}`
      );
    }
  }

  async function handleUnsubscribe() {
    try {
      if (!fetchedUser?.id) throw new Error("User not signed in");
      if (!fetchedCapper?.id) throw new Error("Capper not found");
      await serviceUnsubscribeUser(fetchedUser.id, fetchedCapper.id);
      setAlertType("success");
      setAlertMessage("Unsubscription successful!");
      setIsSubscribed(false);
    } catch (error: any) {
      setAlertType("error");
      setAlertMessage(
        `Failed to unsubscribe: ${error?.message ?? "please try again."}`
      );
    }
  }

  async function fetchSearchedUser() {
    if (!id) return;
    const capper = await getUserByID(id);
    if (!capper?.id) return;
    setFetchedCapper(capper);
    const [subscribers, subscribedTo] = await Promise.all([
      (await capper?.Subscribers()).data,
      (await capper?.SubscribedTo()).data,
    ]);
    setUserSubscribers(subscribers);
    setUserSubscribedTo(subscribedTo);

    const isUserSubscribed = subscribers?.some(
      (sub) => sub.subscriberId === fetchedUser?.id
    );
    setIsSubscribed(isUserSubscribed);
  }

  function CapperHeader() {
    return (
      <div className="shadow flex flex-col lg:flex-row items-center justify-between px-4 md:py-4 relative">
        <div className="flex flex-row items-center">
          {fetchedCapper?.profilePicURI ? (
            <StorageImage
              alt={`${fetchedCapper?.username} picture`}
              path={fetchedCapper?.profilePicURI ?? ""}
              className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-md shadow-black "
            />
          ) : (
            <div className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-md shadow-black flex items-center justify-center cursor-pointer bg-gray-500">
              <AccountCircleIcon className="h-12 w-12 text-white" />
            </div>
          )}
          <div className="ml-4 text-center md:text-left">
            <p className="text-2xl font-bold text-white">
              {fetchedCapper?.username}
            </p>
            <p className="text-lg font-bold text-white">
              {`@${fetchedCapper?.handle}`}
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center md:space-x-6">
          <div className="flex space-x-3 md:space-x-6 items-center">
            <div className="text-center">
              <p className="text-lg font-bold text-white">
                {userSubscribers?.length}
              </p>
              <p className="text-sm text-gray-400">Tailers</p>
            </div>
            <div className="text-center">
              <p className="text-lg font-bold text-white">
                {userSubscribedTo?.length}
              </p>
              <p className="text-sm text-gray-400">Tailing</p>
            </div>
            {isSubscribed ? (
              <Button
                onClick={handleUnsubscribe}
                className="bg-dark-primary-gradient text-white lg:text-lg lg:px-6 lg:py-2 shadow-md shadow-black"
              >
                Unsubscribe
              </Button>
            ) : (
              <Button
                onClick={handleSubscribe}
                disabled={isSubscribed}
                className={`bg-dark-primary-gradient text-white lg:text-lg lg:px-6 lg:py-2 shadow-md shadow-black ${
                  isSubscribed ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {fetchedCapper?.subscriptionCost ? (
                  <>
                    <p>{isSubscribed ? "Already Subscribed" : "Subscribe"}</p>
                    <p className="text-sm">
                      {`\n$${formatPrice(
                        fetchedCapper?.subscriptionCost
                      )}/month`}
                    </p>
                  </>
                ) : isSubscribed ? (
                  "Already Following"
                ) : (
                  "Follow"
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CapperScreen;
