import React from "react";

const SearchHeader: React.FC = () => {
  return (
    <header className="flex justify-between items-center lg:py-2 relative ">
      {/* Centered Navigation */}
      <div className="flex-grow">
        <h1 className="text-white text-2xl">Search</h1>
      </div>
      {/* Right Aligned Branding. It's now positioned absolutely relative to the header */}
      <div className="absolute right-4 text-white cursor-pointer font-bold underline pr-4">
        {/* Adjust the right and padding values as needed */}
        {/* Powered By <span className="text-white">Monster</span>
        <span className="text-primary">Bet</span> */}
      </div>
    </header>
  );
};

export default SearchHeader;
