export const formatPrice = (value: number) => {
  return (value / 100).toFixed(2);
};

export const formatRewardPoints = (value: number) => {
  return Math.round(value / 100).toFixed(0);
};

export const formatWagerWinnings = (value: number, odds: number) => {
  let winnings;
  if (odds > 0) {
    winnings = (value * (odds / 100)).toFixed(2);
  } else {
    winnings = (value / (-odds / 100)).toFixed(2);
  }
  return winnings;
};
