import React from "react";
import { formatPrice } from "utils/format";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface CapperCardProps {
  id: string;
  username: string;
  handle: string;
  profilePictureURL?: string;
  monthlySubscriptionCost?: number | null;
}

const CapperCard: React.FC<CapperCardProps> = ({
  id,
  username,
  handle,
  profilePictureURL,
  // bestTeam,
  // bestTeamImageURL,
  // bestTeamWinPercentage,
  monthlySubscriptionCost,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Prevents the event from bubbling up to the div
    navigate(`/search/${id}`);
  };

  return (
    <div
      onClick={() => navigate(`/search/${id}`)}
      className="flex flex-col lg:flex-row lg:flex-wrap items-center justify-between rounded-lg p-4 my-2.5 bg-card-gradient relative shadow-sm shadow-black overflow-hidden w-full"
    >
      {/* Left Section: Avatar and User Info */}
      <div className="flex items-center space-x-4 mb-4 lg:mb-0">
        {profilePictureURL ? (
          <StorageImage
            alt={`${username} picture`}
            path={profilePictureURL}
            className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-md shadow-black border-2 border-white"
          />
        ) : (
          <img className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-md shadow-black flex items-center justify-center cursor-pointer bg-gray-500">
            <AccountCircleIcon className="h-12 w-12 text-white" />
          </img>
        )}
        <div className="flex flex-col justify-center">
          <div className="font-bold text-lg">{username}</div>
          <div className="text-gray-800">@{handle}</div>
        </div>
      </div>

      {/* Middle Section: Winrate info */}
      <div className="flex-1 text-center my-4 lg:my-0">
        {/* <h3 className="font-bold">WinRate</h3> */}
        {/* <h3 className="font-bold">Best Team</h3>
        {bestTeam ? (
          <>
            <img
              src={bestTeamImageURL}
              alt={convertToCapitalCase(bestTeam) + " image"}
              className="mx-auto h-12 w-12 lg:h-24 lg:w-24"
            />
            <p className="font-bold">{convertToCapitalCase(bestTeam)}</p>
            {bestTeamWinPercentage ? (
              <p>
                This Capper wins
                <span className="text-mid-purple font-bold">
                  {` ${Math.round(bestTeamWinPercentage)}% `}
                </span>
                of their bets on this team
              </p>
            ) : (
              <p>
                Capper's best team win rate is
                <span className="text-mid-purple font-bold">
                  {`yet to be determined.`}
                </span>
              </p>
            )}
          </>
        ) : (
          <>
            <p>This Capper's best team is yet to be determined.</p>
          </>
        )} */}
      </div>

      {/* Right Section: Subscription Button */}
      <div className="flex flex-col items-center lg:items-end w-full lg:w-auto mt-4 lg:mt-0">
        <Button
          size="lg"
          className="bg-dark-primary-gradient px-6 py-2 shadow-lg shadow-black text-sm"
          onClick={handleButtonClick}
        >
          {monthlySubscriptionCost
            ? `$${formatPrice(monthlySubscriptionCost)} / month`
            : "Follow"}
        </Button>
      </div>
    </div>
  );
};

export default CapperCard;
