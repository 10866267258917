import React, { useEffect, useState } from "react";
import { BetLeg, Team, Player, BetEntity, BetResult } from "models";
import { convertToCapitalCase } from "utils/format";
import { simpleReformatDate } from "utils/format/formatDateTime";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Schema } from "../../../amplify/data/resource";

interface BetLegCardProps {
  betLeg: Schema["Bet"]["type"]["legs"][0];
  date: string;
}

const BetLegCard: React.FC<BetLegCardProps> = ({ betLeg, date }) => {
  const [displayEntity, setDisplayEntity] = useState<string>("");
  const [opponentEntity, setOpponentEntity] = useState<string>("");
  const [imgURI, setImgURI] = useState<string>("");

  useEffect(() => {
    const fetchEntity = async () => {
      let entityName = "ENTITY NAME";
      let opponentName = "OPPONENT";
      let entityImgURI = "";

      if (betLeg?.entity === BetEntity.PLAYER) {
        const player = betLeg?.player;
        entityName = player?.name ?? "";
      } else if (betLeg?.entity === BetEntity.TEAM) {
        const team = betLeg?.team;
        entityName = team?.name ?? "";
        entityImgURI = team?.logoUrl ?? "";
      }

      // if (betLeg?.opponentTeam) {
      //   const opponentTeam = betLeg.opponentTeam as Team;
      //   opponentName = opponentTeam.name;
      // } else if (betLeg.opponentPlayer) {
      //   const opponentPlayer = betLeg.opponentPlayer as Player;
      //   opponentName = opponentPlayer.name;
      // }

      setDisplayEntity(entityName);
      setOpponentEntity(opponentName);
      setImgURI(entityImgURI);
    };

    fetchEntity();
  }, [betLeg]);

  const getOdds = () => {
    return betLeg?.odds || 0;
  };

  const getResult = () => {
    return betLeg?.result || "PENDING";
  };

  const odds = getOdds();
  const result = getResult();

  const cardBgColor =
    (result === "WIN" && "bg-bet-won-gradient") ||
    (result === "LOSS" && "bg-bet-lost-gradient") ||
    "bg-bet-active-gradient";

  return (
    <div
      className={`flex items-center justify-between p-4 text-white border-b-2 border-black ${cardBgColor}`}
    >
      {imgURI ? (
        <img
          src={imgURI}
          alt={`${displayEntity} image`}
          className="w-8 h-8 mr-2"
        />
      ) : (
        <AccountCircleIcon className="w-8 h-8 mr-2" />
      )}
      <div className="flex-grow flex-col flex-basis-0 min-w-3/5 text-center px-2 space-evenly text-black">
        <div className="flex flex-col">
          <div className="font-bold">
            <span>{displayEntity} </span>
            <span>{odds > 0 ? `+${odds}` : odds}</span>
          </div>
          <p>{convertToCapitalCase(betLeg?.type ?? "")}</p>
          <p>{betLeg?.event}</p>
        </div>
      </div>
      <div className="flex flex-col text-center text-black">
        <div>
          <span>{simpleReformatDate(date)}</span>
        </div>
        <div>
          <span>
            Bet {result === "PENDING" ? "Active" : convertToCapitalCase(result)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BetLegCard;
