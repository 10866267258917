import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";
import { CapTailTheme } from "theme";
import AuthContextProvider, { useAuthContext } from "context/AuthContext";

import { HomeScreen } from "screens";
import BetScreen from "screens/bet";
import SearchScreen from "screens/search";
import CapperScreen from "screens/search/[id]";
import ProfileScreen from "screens/profile";
import SignUp from "screens/profile/Signup";
import SignIn from "screens/profile/SignIn";
import ForgotPassword from "screens/profile/ForgotPassword";
import Navbar from "components/nav";
import LandingPage from "screens/landing";
import LoadingScreen from "components/Loading";
import PrivateRoute from "components/PrivateRoute";
import PublicRoute from "components/PublicRoute";
import output from "./amplify_outputs.json";

Amplify.configure(output);

function App() {
  const { cognitoUser, loading, fetchedUser } = useAuthContext();

  if (loading) return <LoadingScreen />;

  return (
    <ThemeProvider theme={CapTailTheme}>
      <div className="min-h-screen md:flex bg-background-gradient">
        <Router>
          {fetchedUser?.id && cognitoUser ? (
            <>
              <Navbar />
              <div className="w-0.5 bg-white" />
            </>
          ) : null}
          <div className="flex-grow h-screen overflow-y-auto overscroll-y-auto overscroll-contain">
            <Routes>
              {/* Public Routes */}
              <Route element={<PublicRoute />}>
                <Route path="/landing" element={<LandingPage />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Route>
              {/* Auth protected routes */}
              <Route element={<PrivateRoute />}>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/my-bets" element={<HomeScreen />} />
                <Route path="/popular" element={<HomeScreen />} />
                <Route path="/bet" element={<BetScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route
                  path="/profile/settled-bets"
                  element={<ProfileScreen />}
                />
                <Route path="/profile/earnings" element={<ProfileScreen />} />
                <Route
                  path="/profile/performance"
                  element={<ProfileScreen />}
                />
                <Route path="/search" element={<SearchScreen />} />
                <Route path="/s earch/:id" element={<CapperScreen />} />
                <Route
                  path="/search/:id/settled-bets"
                  element={<CapperScreen />}
                />
                <Route path="/search/:id/earnings" element={<CapperScreen />} />
                <Route
                  path="/search/:id/performance"
                  element={<CapperScreen />}
                />
              </Route>
            </Routes>
          </div>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default function WrappedApp() {
  return (
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  );
}
