import { DataStore, Predicates, SortDirection } from "aws-amplify/datastore";
import { TailedBet, Bet, User } from "../models"; // Adjust the path as necessary
import { BetService } from "services";

export const fetchTailedBetsSince = async (
  userId: string,
  fromDate: Date
): Promise<any[]> => {
  try {
    const fromDateString = fromDate.toISOString(); // Convert date to ISO string for comparison
    // Fetch tailed bets where the user is the one who tailed the bet and the bet was created after 'fromDate'
    // const {data, errors} = await client.models.
    const fetchedTailedBets = await DataStore.query(
      TailedBet,
      (tb) =>
        tb.and((tb) => [
          tb.userID.eq(userId),
          tb.bet.createdAt.ge(fromDateString),
        ]),
      {
        sort: (tb) => tb.createdAt(SortDirection.DESCENDING), // Assuming sorting needs to be applied on the bet's creation date
      }
    );

    // Optionally, fetch related bet details if necessary
    const tailedBetsWithDetails = await Promise.all(
      fetchedTailedBets.map(async (tailedBet) => {
        const bet = await DataStore.query(Bet, tailedBet.betID); // Fetch each related bet
        const user = await DataStore.query(User, tailedBet.userID); // Fetch the user details if necessary
        return { ...tailedBet, bet, user };
      })
    );

    return tailedBetsWithDetails;
  } catch (err) {
    console.warn("Error in fetchTailedBetsSince:", err);
    return [];
  }
};

export const fetchUserTailedBets = async (userId: string) => {
  try {
    // Need the b.Wager.ne(0) to be able to sort
    const fetchedTailedBets = await DataStore.query(
      TailedBet,
      (tb) => tb.userID.eq(userId),
      {
        sort: (b) => b.createdAt(SortDirection.DESCENDING),
      }
    );

    return fetchedTailedBets;
  } catch (err) {
    console.warn("error: TailedBetservice fetchUserTailedBets", err);
    return [];
  }
};

export const createTailedBet = async (input: any) => {
  try {
    // Implement the logic to create a new TailedBet instance
    // and save it to the DataStore
    const createdTailedBet = await DataStore.save(
      new TailedBet({
        userID: input.userID,
        betID: input.betID,
        wager: input.wager,
      })
    );
    return createdTailedBet;
  } catch (err) {
    console.warn("error: TailedBetservice createTailedBet", err);
    return {};
  }
};

export const subscribeToTailedBets = (
  userId: string,
  callback: (data: { bet: Bet; user?: User; tailedBet?: TailedBet }[]) => void
) => {
  const subscription = DataStore.observeQuery(TailedBet, (tb) =>
    tb.userID.eq(userId)
  ).subscribe(async () => {
    try {
      const tailedBets = await fetchUserTailedBets(userId);
      const tailedBetsWithDetails = await Promise.all(
        tailedBets.map(async (tailedBet) => {
          const bet = await DataStore.query(Bet, tailedBet.betID);
          const user = await bet?.User;
          return { bet, user, tailedBet };
        })
      );
      const filteredDetails = tailedBetsWithDetails.filter(
        (detail) => detail.bet !== undefined && detail.user !== undefined
      ) as { bet: Bet; user?: User }[];
      callback(filteredDetails);
    } catch (err) {
      console.error("Error fetching tailed bets:", err);
    }
  });

  return subscription;
};
export const subscribeToUserBets = (
  userId: string,
  callback: (data: any[]) => void
) => {
  const subscription = DataStore.observeQuery(Bet, (b) =>
    b.userID.eq(userId)
  ).subscribe(async () => {
    try {
      const userBets = await BetService.fetchUserBetsWithFilters(userId, true);
      const bets: any = userBets.map((data) => data.bet);
      callback(bets);
    } catch (err) {
      console.error("Error fetching user bets:", err);
    }
  });

  return subscription;
};
