import { getUrl } from "aws-amplify/storage";

export async function getPresignedURL(path: string) {
  try {
    const linkToStorageFile = await getUrl({
      path: path,
      // Alternatively, path: ({identityId}) => `album/{identityId}/1.jpg`
    });
    return linkToStorageFile;
  } catch (err: any) {
    throw new Error("getPresignedURL error: ", err?.message);
  }
}
