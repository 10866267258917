const colors = {
  // 405DE6
  primary: "#FF873F", // New primary color based on the orange theme
  "mid-orange": "#FF8C42", // Mid-tone orange
  "dark-orange": "#E56E0A", // Darker orange
  "modal-black": "#1E1E1E",
  white: "#FFFFFF",
  grey: "#737373", // Mid-tone grey
  "dark-grey": "#2E2A2A",
  "light-grey": "#D9D9D9",
  silver: "#C0C0C0",
  accent: "#ED4956",
  border: "#dcdcdc",
  error: "#ED4956",
  black: "#000",
  lightgrey: "#cccccc",
};

export default colors;
