import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Input,
} from "@material-tailwind/react";
import { useAuthContext } from "context/AuthContext";
import TailFeeModal from "./TailFeeModal";
import { formatPrice } from "utils/format";
import { useForm, SubmitHandler } from "react-hook-form";

interface EditProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type FormValues = {
  userName: string;
  profilePicURI: string;
  tailFee: number;
};

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { fetchedUser } = useAuthContext();
  const [tailFeeModalVisible, setTailFeeModalVisible] =
    useState<boolean>(false);
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      userName: fetchedUser?.username || "",
      tailFee: fetchedUser?.subscriptionCost || 0, // Set the default tail fee value
      profilePicURI: fetchedUser?.profilePicURI || "",
    },
  });

  const userName = watch("userName");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      let profilePicURI = data.profilePicURI;
      if (imageFile) {
        // TODO: connect the image uploading to S3
        // Upload the image file to your storage solution and get the URI
        // profilePicURI = await uploadImage(imageFile); // Implement the uploadImage function
      }
      const updatedData = {
        ...data,
        handle: data.userName.toLowerCase(),
        profilePicURI,
      };

      // Update user information in DataStore
      onClose();
    } catch (err) {
      console.error("Error updating user information:", err);
    }
  };

  const handleTailFeeChange = (input: any) => {
    // Update the form value with the new tail fee
    setTailFeeModalVisible(false);
  };

  const closeTailFeeModal = () => {
    setTailFeeModalVisible(false);
  };

  const modalHandler = () => {
    tailFeeModalVisible ? onClose() : setTailFeeModalVisible(false);
  };

  return (
    <>
      <Dialog open={isOpen} handler={modalHandler} size="lg">
        <DialogHeader>
          <h2 className="text-2xl font-bold">Edit Profile</h2>
        </DialogHeader>
        <DialogBody>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Username
              </label>
              <Input
                type="text"
                {...register("userName", { required: "Username is required" })}
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              />
              {errors.userName && (
                <p className="text-red-500 text-sm">
                  {errors.userName.message}
                </p>
              )}
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Handle
              </label>
              <Input
                type="text"
                value={`@${userName.toLowerCase()}`}
                readOnly
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none bg-gray-100"
              />
            </div>
            {/* <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Profile Picture
              </label>
              <Input
                type="file"
                accept="image/*"
                onChange={handleChange}
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              />
              {preview && (
                <div className="mt-2">
                  <img
                    src={preview as string}
                    alt="Profile Preview"
                    className="h-32 w-32 rounded-full border-2 border-purple-500"
                  />
                </div>
              )}
            </div> */}
          </form>
        </DialogBody>
        <DialogFooter className="justify-between">
          <Button
            type="button"
            onClick={() => setTailFeeModalVisible(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Set Tail Fee
          </Button>
          <div>
            <Button
              type="button"
              onClick={onClose}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
      <TailFeeModal
        isOpen={tailFeeModalVisible}
        onClose={closeTailFeeModal}
        callBackFunction={handleTailFeeChange}
        originalTailFee={getValues("tailFee") ?? 0}
      />
    </>
  );
};

export default EditProfileModal;
