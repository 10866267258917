import React, { createContext, useContext, useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser } from "aws-amplify/auth";
import { useDataStoreListener } from "hooks/useDataStoreListener"; // Adjust the import path as needed
import { subscribeToUserUpdates } from "services/UserService";
import { Schema } from "../../amplify/data/resource";

type AuthContextType = {
  cognitoUser?: any | null;
  fetchedUser?: Schema["User"]["type"] | null;
  loading: boolean;
  userUpdated: boolean;
};

export const AuthContext = createContext<AuthContextType>({
  cognitoUser: null,
  fetchedUser: null,
  loading: true,
  userUpdated: false,
});

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [cognitoUser, setCognitoUser] = useState<any | null>(null);
  const [fetchedUser, setFetchedUser] = useState<Schema["User"]["type"] | null>(
    null
  );
  const [userSubscription, setUserSubscription] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [userUpdated, setUserUpdated] = useState<boolean>(false);

  const fetchUser = async (email: string) => {
    try {
      if (userSubscription) {
        userSubscription.unsubscribe();
      }
      const subscription = subscribeToUserUpdates(email, (user) => {
        setFetchedUser(user);
        setUserUpdated((prev) => !prev);
      });
      setUserSubscription(subscription);
    } catch (err) {
      console.warn("error fetchUser", err);
    } finally {
      setLoading(false);
    }
  };

  const checkUser = async () => {
    setLoading(true);
    try {
      const authUser = await getCurrentUser();
      if (!authUser.userId || !authUser.signInDetails?.loginId) {
        // No auth user
        throw new Error("Missing email or sub in user attributes");
      }
      setCognitoUser(authUser);
      await fetchUser(authUser.signInDetails?.loginId);
    } catch (error) {
      console.warn("error checkUser", error);
      setCognitoUser(null);
      setFetchedUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    /* start listening for auth events */
    const hubListenerCancelToken = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          checkUser();
          break;
        case "signedOut":
          checkUser();
          break;
        case "tokenRefresh":
          checkUser();
          break;
        case "tokenRefresh_failure":
          checkUser();
          break;
        case "signInWithRedirect":
          checkUser();
          break;
        case "signInWithRedirect_failure":
          checkUser();
          break;
        case "customOAuthState":
          checkUser();
          break;
      }
    });

    return () => {
      hubListenerCancelToken(); // stop listening for messages
    };
  }, []);

  const { unSubscribe } = useDataStoreListener(() => {
    checkUser(); // Call checkUser when DataStore sync is complete
  });

  useEffect(() => {
    return () => {
      if (userSubscription) {
        userSubscription.unsubscribe();
      }
      unSubscribe();
    };
  }, [userSubscription, unSubscribe]);

  useEffect(() => {
    checkUser();
  }, []);

  const value = { cognitoUser, fetchedUser, loading, userUpdated };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextType => useContext(AuthContext);

export default AuthContextProvider;
