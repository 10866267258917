import { CreateUserInput, UpdateUserInput } from "../../API";
import { Schema } from "../../amplify/data/resource";
import { generateClient } from "aws-amplify/api";

const client = generateClient<Schema>();

export const getUserByID = async (userID: string) => {
  try {
    const { data, errors } = await client.models.User.get({
      id: userID,
    });
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: UserService getUserByID", err);
    return;
  }
};

export const serviceCreateUser = async (input: CreateUserInput) => {
  try {
    const [existingEmailUsers] = await Promise.all([
      client.models.User.listUserByEmail(
        {
          email: input.email,
        },
        { authMode: "identityPool" }
      ),
      // client.models.User.listUserByPhoneNumber({
      //   phoneNumber: input.phoneNumber,
      // }),
    ]);

    if (existingEmailUsers.errors)
      throw new Error(JSON.stringify(existingEmailUsers.errors));
    // if (existingPhoneUsers.errors)
    //   throw new Error(JSON.stringify(existingPhoneUsers.errors));
    if (existingEmailUsers.data.length > 0)
      throw new Error("A user with that email already exists");

    const { data, errors } = await client.models.User.create(
      {
        username: input.username,
        handle: input.handle,
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        // phoneNumber: input.phoneNumber,
        profilePicURI: "",
        subscriptionCost: 0,
      },
      {
        authMode: "identityPool",
      }
    );
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: UserService serviceCreateUser", err);
    return;
  }
};

export const updateUser = async (input: UpdateUserInput) => {
  try {
    const { data, errors } = await client.models.User.update({
      id: input.id,
      profilePicURI: input.profilePicURI ?? undefined,
      username: input.username ?? undefined,
      handle: input.username ? input.username.toLowerCase() : undefined,
      subscriptionCost: input.subscriptionCost ?? undefined,
    });
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: UserService updateUser", err);
    return;
  }
};

export const searchUsers = async (searchText: string, userId?: string) => {
  try {
    const lowerCaseText = searchText.toLowerCase();
    const { data, errors } = await client.models.User.list({
      filter: {
        and: {
          or: [
            { handle: { contains: lowerCaseText } },
            { username: { contains: searchText } },
            { email: { contains: lowerCaseText } },
            { email: { contains: searchText } },
          ],
          id: {
            ne: userId ?? "0",
          },
        },
      },
    });
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: UserService searchUsers", err, searchText);
    return [];
  }
};

export const fetchAllUsers = async () => {
  try {
    const { data, errors } = await client.models.User.list();
    if (errors) throw new Error(JSON.stringify(errors));
    return data;
  } catch (err) {
    console.warn("error: UserService fetchAllUsers", err);
    return [];
  }
};

export const subscribeToUserUpdates = (
  email: string,
  callback: (action: any) => void
) => {
  try {
    return client.models.User.observeQuery({
      filter: {
        email: { eq: email },
      },
    }).subscribe({
      next: ({ items }) => {
        if (items.length > 0) {
          callback(items[0]);
        } else {
          callback(null);
        }
      },
      error: (error) => {
        console.warn("observeQuery error", error);
        callback(null);
      },
    });
  } catch (error) {
    console.warn("subscribeToUserUpdates error", error);
    callback(null);
  }
};
