import BetCard from "components/cards/BetCard";
import HomeHeader from "./header";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthContext } from "context/AuthContext";
import { Button } from "@material-tailwind/react";
import { BetService } from "services";
import { Schema } from "../../../amplify/data/resource";
import {
  subscribeToTailedBets,
  subscribeToUserBets,
} from "services/TailedBetService";
import { useDataStoreListener } from "hooks/useDataStoreListener";

const HomeScreen = () => {
  const { fetchedUser } = useAuthContext();
  const [tailedBets, setTailedBets] = useState<
    {
      bet: any;
      user?: any;
      tailedBet?: any;
    }[]
  >([]);
  const [popularBets, setPopularBets] = useState<{ bet: any; user?: any }[]>(
    []
  );
  const [userBets, setUserBets] = useState<any[]>([]);
  const { unSubscribe } = useDataStoreListener(() => {
    if (popularBets?.length === 0) {
      getPopularBets();
    } else {
      unSubscribe();
    }
  });
  const location = useLocation();

  useEffect(() => {
    if (fetchedUser?.id) {
      const tailedBetsSubscription = subscribeToTailedBets(
        fetchedUser.id,
        setTailedBets
      );
      const userBetsSubscription = subscribeToUserBets(
        fetchedUser.id,
        setUserBets
      );

      return () => {
        tailedBetsSubscription.unsubscribe();
        userBetsSubscription.unsubscribe();
      };
    }
  }, [fetchedUser?.id]);

  useEffect(() => {
    if (popularBets.length || location.pathname !== "/popular") return;
    getPopularBets();
  }, [location.pathname]);

  const getPopularBets = async () => {
    const fetchedBets = await BetService.fetchPopularBetsWithUser();
    setPopularBets(fetchedBets);
  };

  const renderCTA = (message: string, buttonText?: string, link?: string) => (
    <div className="flex flex-col items-center justify-center h-full">
      <p className="text-lg text-black text-center">{message}</p>
      {buttonText && link && (
        <Link to={link}>
          <Button
            size="lg"
            className="bg-black text-white py-2 px-4 rounded mt-4"
          >
            {buttonText}
          </Button>
        </Link>
      )}
    </div>
  );

  const getDataForRoute = () => {
    switch (location.pathname) {
      case "/my-bets":
        return userBets && userBets.length > 0
          ? userBets.map((bet) => (
              <div className="my-4 mx-4 lg:mx-24" key={bet.id}>
                <BetCard
                  betID={bet.id}
                  userID={bet.userId}
                  username={fetchedUser?.username!}
                  handle={fetchedUser?.handle!}
                  profilePictureURI={fetchedUser?.profilePicURI}
                  betDate={bet.createdAt!}
                  odds={bet.combinedOdds}
                  wager={bet.wager}
                  result={bet.result}
                  active={bet.isActive!}
                  legs={bet.legs}
                  // Marking user's own bets as tailed for ease
                  tailing={true}
                />
              </div>
            ))
          : renderCTA(
              "Looks like you haven't placed a bet yet. Lets fix that!",
              "Create a bet!",
              "/bet"
            );
      case "/popular":
        return popularBets && popularBets.length
          ? popularBets?.map(({ bet, user }) => (
              <div className="my-4 lg:my-8 mx-4 lg:mx-24" key={bet.id}>
                <BetCard
                  betID={bet.id}
                  userID={bet.userId}
                  username={user?.username!}
                  handle={user?.handle!}
                  profilePictureURI={fetchedUser?.profilePicURI}
                  betDate={bet.createdAt!}
                  odds={bet.combinedOdds}
                  wager={bet.wager}
                  result={bet.result}
                  active={bet.isActive!}
                  legs={bet.legs}
                  tailing={
                    tailedBets.find((tailedBet) => tailedBet.bet.id === bet.id)
                      ? true
                      : user.id === fetchedUser?.id
                  }
                />
              </div>
            ))
          : renderCTA("No popular bets found. Please check back later!");
      default:
        return tailedBets && tailedBets.length > 0
          ? tailedBets.map(({ bet, user, tailedBet }) => (
              <div className="mx-4 lg:mx-24" key={bet.id}>
                <BetCard
                  betID={bet.id}
                  userID={bet.userId}
                  username={user?.username!}
                  handle={user?.handle!}
                  profilePictureURI={fetchedUser?.profilePicURI}
                  betDate={bet.createdAt!}
                  odds={bet.combinedOdds}
                  wager={bet.wager}
                  result={bet.result}
                  active={bet.isActive!}
                  legs={bet.legs}
                  tailing={true}
                  tailedWager={tailedBet?.wager}
                />
              </div>
            ))
          : renderCTA(
              "Looks like you haven't tailed any bets yet...",
              "Need a suggestion?",
              "/popular"
            );
    }
  };

  return (
    <div>
      <HomeHeader />
      <div className="lg:my-8 h-[calc(100vh)] overflow-auto">
        {getDataForRoute()}
      </div>
    </div>
  );
};

export default HomeScreen;
