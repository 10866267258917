import { useEffect, useState } from "react";
import { Button, IconButton } from "@material-tailwind/react";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthContext } from "context/AuthContext";
import { formatPrice } from "utils/format";
import EditProfileModal from "components/modals/EditProfileModal";
import TailFeeModal from "components/modals/TailFeeModal";
import { subscribeToUserUpdates, updateUser } from "services/UserService";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { handleS3Upload } from "utils/helper/s3Upload";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import { v4 as uuidv4 } from "uuid";

const ProfileHeader = () => {
  const [tailFeeModalVisible, setTailFeeModalVisible] =
    useState<boolean>(false);
  const [editProfileModalVisible, setEditProfileModalVisible] =
    useState<boolean>(false);
  const [userSubscribers, setUserSubcribers] = useState<any[]>();
  const [userSubscribedTo, setUserSubscribedTo] = useState<any[]>();
  const { fetchedUser } = useAuthContext();
  const [realTimeFetchedUser, setRealTimeFetchedUser] = useState(fetchedUser);

  useEffect(() => {
    if (fetchedUser?.id) {
      const subscription = subscribeToUserUpdates(
        fetchedUser.email,
        setRealTimeFetchedUser
      );
      return () => {
        subscription?.unsubscribe();
      };
    }
  }, [fetchedUser]);

  useEffect(() => {
    fetchSubscribers();
    fetchSubscribedTo();
  }, [realTimeFetchedUser]);

  const fetchSubscribers = async () => {
    if (realTimeFetchedUser?.id) {
      const followersArray = (await realTimeFetchedUser.Subscribers()).data;
      setUserSubcribers(followersArray);
    }
  };

  const fetchSubscribedTo = async () => {
    if (realTimeFetchedUser?.id) {
      const followingArray = (await realTimeFetchedUser.SubscribedTo()).data;
      setUserSubscribedTo(followingArray);
    }
  };

  const handleTailFeeModal = () => {
    setTailFeeModalVisible(!tailFeeModalVisible);
  };

  const handleEditProfileModal = () => {
    setEditProfileModalVisible(!editProfileModalVisible);
  };

  const handleProfilePicUpload = () => {
    if (!fetchedUser?.id) return;
    // Open file upload dialog
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = async (event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        // Handle file upload logic here
        // Implement the logic to upload the profile picture to S3 or your desired storage
        const s3result = await handleS3Upload({
          path: `public/images/${fetchedUser?.id}/profile/${uuidv4()}`,
          data: file,
        });
        if (!s3result)
          throw new Error("Sorry, we ran into an issue uploading your image.");
        // TODO: update to add it to the user obj in db
        const updatedUser = await updateUser({
          id: fetchedUser?.id,
          profilePicURI: s3result.path,
        });
      }
    };
    fileInput.click();
  };

  return (
    <div className="flex flex-row lg:flex-row items-center justify-between border-b border-white md:py-2 md:py-4 max-h-1/4 px-4">
      <div className="flex flex-col items-center md:flex-row md:items-center md:pt-2 md:pt-4">
        {realTimeFetchedUser?.profilePicURI ? (
          <StorageImage
            onClick={handleProfilePicUpload}
            alt={`${realTimeFetchedUser?.username} profile picture`}
            path={realTimeFetchedUser?.profilePicURI}
            className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-md shadow-black "
          />
        ) : (
          <div
            onClick={handleProfilePicUpload}
            className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-md shadow-black flex items-center justify-center cursor-pointer bg-gray-500"
          >
            <CloudUploadIcon className="h-12 w-12 text-white" />
          </div>
        )}
        <div className="mt-2 md:mt-0 md:ml-2 md:ml-4 text-center md:text-left">
          <p className="text-xl md:text-2xl font-bold text-white">
            {realTimeFetchedUser?.username}
          </p>
          <p className="text-md md:text-lg font-bold text-white">
            {`@${realTimeFetchedUser?.handle}`}
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center mt-2 md:mt-4 md:my-0">
        <div className="flex space-x-2 md:space-x-6">
          <div className="text-center">
            <p className="text-md md:text-lg font-bold text-white">
              {userSubscribers?.length}
            </p>
            <p className="text-xs md:text-sm text-gray-400">Tailers</p>
          </div>
          <div className="text-center">
            <p className="text-md md:text-lg font-bold text-white">
              {userSubscribedTo?.length}
            </p>
            <p className="text-xs md:text-sm text-gray-400">Tailing</p>
          </div>
        </div>
        <div className="mt-2 md:mt-4 md:my-0 md:ml-6 flex items-center space-x-4">
          <Button
            className="bg-dark-primary-gradient text-white text-sm md:text-lg px-4 md:px-6 py-1 md:py-2 shadow-md shadow-black"
            onClick={handleTailFeeModal}
          >
            <p className="text-xs md:text-sm">
              {realTimeFetchedUser?.subscriptionCost
                ? `$${formatPrice(realTimeFetchedUser?.subscriptionCost)}/month`
                : `Free`}
            </p>
          </Button>
          <IconButton
            onClick={handleEditProfileModal}
            className="bg-light-primary-gradient text-white text-sm md:text-lg p-1 md:p-2 shadow-md shadow-black"
          >
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <TailFeeModal
        isOpen={tailFeeModalVisible}
        onClose={handleTailFeeModal}
        callBackFunction={() => null}
        originalTailFee={realTimeFetchedUser?.subscriptionCost ?? 0}
      />
      <EditProfileModal
        isOpen={editProfileModalVisible}
        onClose={handleEditProfileModal}
      />
    </div>
  );
};

export default ProfileHeader;
