import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Input,
  Button,
  Card,
  Typography,
  Alert,
} from "@material-tailwind/react";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import { Link } from "react-router-dom";
import { validatePassword } from "utils/validate/validatePassword";
import { validateEmail } from "utils/validate";

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      confirmationCode: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const [step, setStep] = useState(0); // 0 = email form, 1 = code verification, 2 = reset password
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<"success" | "error">("success");

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Card className="w-full max-w-sm p-24 bg-opacity-60 bg-black border-2 border-mid-purple">
        <Typography variant="h5" className="mb-4 text-center text-white">
          Forgot Password
        </Typography>
        {alertMessage && (
          <Alert
            color={getAlertColor()}
            onClose={handleAlertClose}
            className="mb-4"
          >
            {alertMessage}
          </Alert>
        )}
        <form
          onSubmit={handleSubmit(
            step === 0 ? handleSendCode : handleVerifyCode
          )}
          className="space-y-4"
        >
          {step === 0 && (
            <>
              <Input
                type="email"
                size="lg"
                label="Email"
                color="white"
                {...register("email", {
                  required: "Email is required",
                  validate: validateEmail,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                error={errors.email ? true : false}
              />
              {errors.email && (
                <p className="text-red-700">{`${errors.email.message}`}</p>
              )}
            </>
          )}
          {step === 1 && (
            <>
              <Input
                type="text"
                size="lg"
                label="Confirmation Code"
                color="white"
                {...register("confirmationCode", {
                  required: "Code is required",
                })}
              />
              {errors.confirmationCode && (
                <p className="text-red-700">{`${errors.confirmationCode.message}`}</p>
              )}
              <Input
                type="password"
                size="lg"
                label="New Password"
                color="white"
                {...register("newPassword", {
                  required: "New password is required",
                  validate: validatePassword,
                })}
                error={errors.newPassword ? true : false}
              />
              {errors.newPassword && (
                <p className="text-red-700">{`${errors.newPassword.message}`}</p>
              )}
              <Input
                type="password"
                size="lg"
                label="Confirm New Password"
                color="white"
                {...register("confirmPassword", {
                  required: "Please confirm your new password required",
                  validate: validateConfirmPassword,
                })}
                error={errors.confirmPassword ? true : false}
              />
              {errors.confirmPassword && (
                <p className="text-red-700">{`${errors.confirmPassword.message}`}</p>
              )}
            </>
          )}
          {step !== 2 && (
            <Button
              type="submit"
              fullWidth={true}
              className="bg-dark-primary-gradient text-white text-lg md:text-xl px-6 py-2 my-12 shadow-md shadow-black"
            >
              {step === 0 ? "Send Code" : "Reset Password"}
            </Button>
          )}
        </form>
        <div className="flex justify-between items-center mt-4 space-x-4">
          <Link to={"/sign-in"}>
            <Button type="button" variant="text" className="text-white">
              Back to Sign In
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  );

  async function handleSendCode(data: { email: any }) {
    try {
      const { email } = data;
      await resetPassword({ username: email });
      setAlertType("success");
      setAlertMessage("Verification code sent. Please check your email.");
      setStep(1);
    } catch (error) {
      console.error("Error sending code:", error);
      setError("email", { type: "manual", message: "Failed to send code" });
      setAlertType("error");
      setAlertMessage("Failed to send verification code.");
    }
  }

  async function handleVerifyCode(data: {
    email: any;
    confirmationCode: any;
    newPassword: any;
  }) {
    try {
      const { email, confirmationCode, newPassword } = data;
      const response = await confirmResetPassword({
        username: email,
        confirmationCode,
        newPassword,
      });
      setAlertType("success");
      setAlertMessage("Password reset successful.");
      setStep(2); // Proceed to login or other relevant page
    } catch (error) {
      console.error("Error resetting password:", error);
      setError("confirmationCode", {
        type: "manual",
        message: "Failed to verify code",
      });
      setAlertType("error");
      setAlertMessage("Failed to reset password.");
    }
  }

  function validateConfirmPassword(value: string) {
    if (value !== getValues("newPassword")) {
      return "Passwords do not match";
    }
    return true;
  }

  function handleAlertClose() {
    setAlertMessage(null);
  }

  function getAlertColor() {
    switch (alertType) {
      case "success":
        return "green";
      default:
        // error
        return "red";
    }
  }
}
