import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Input,
  Button,
  Card,
  Typography,
  Checkbox,
  Alert,
} from "@material-tailwind/react";
import { signUp, confirmSignUp } from "aws-amplify/auth";
import { UserService } from "services";
import { validateEmail, validateName } from "utils/validate";
import { validatePassword } from "utils/validate/validatePassword";
import { Link, useNavigate } from "react-router-dom";

export default function SignUp() {
  const [step, setStep] = useState<"REGISTRATION" | "CONFIRMATION">(
    "REGISTRATION"
  );
  const [cognitoSub, setCognitoSub] = useState<string>();
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      userName: "",
      verificationCode: "",
      agreeTerms: false,
    },
    mode: "all",
  });

  const handleAlertClose = () => {
    setAlertMessage(null);
  };

  const getAlertColor = () => {
    switch (alertType) {
      case "success":
        return "green";
      default:
        // error
        return "red";
    }
  };

  return (
    <div className="flex items-center justify-center md:my-16">
      <Card className="w-full max-w-sm md:max-w-xl p-4 bg-black bg-opacity-60 border-2 border-mid-purple overflow-y-auto h-[100vh] mb-24 lg:h-auto lg:pb-0">
        <Typography variant="h5" color="white" className="mb-4 text-center">
          Sign Up
        </Typography>
        {alertMessage && (
          <Alert
            color={getAlertColor()}
            onClose={handleAlertClose}
            className="mb-4"
          >
            {alertMessage}
          </Alert>
        )}
        <form>{renderForm()}</form>
        <Link to={"/sign-in"}>
          <Button type="button" variant="text" className="mt-6 text-white">
            Have an account? Sign in
          </Button>
        </Link>
      </Card>
    </div>
  );

  function renderForm() {
    if (step === "REGISTRATION") {
      return (
        <>
          <Input
            {...register("firstName", {
              required: "First name is required",
              validate: validateName,
            })}
            type="text"
            size="lg"
            label="First Name"
            variant="standard"
            color="white"
            error={errors.firstName ? true : false}
          />
          {errors.firstName && (
            <p className="text-red-700">{`${errors.firstName.message}`}</p>
          )}
          <div className="my-8">
            <Input
              {...register("lastName", {
                required: "Last name is required",
                validate: validateName,
              })}
              type="text"
              size="lg"
              label="Last Name"
              variant="standard"
              color="white"
              error={errors.lastName ? true : false}
            />
            {errors.lastName && (
              <p className="text-red-700">{`${errors.lastName.message}`}</p>
            )}
          </div>
          <Input
            {...register("userName", {
              required: "User name is required",
              validate: validateName,
            })}
            type="text"
            size="lg"
            label="User Name"
            variant="standard"
            color="white"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            }
            error={errors.userName ? true : false}
          />
          {errors.userName && (
            <p className="text-red-700">{`${errors.userName.message}`}</p>
          )}
          <div className="my-8">
            <Input
              {...register("email", {
                required: "Email is required",
                validate: validateEmail,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
              type="email"
              size="lg"
              label="Email"
              variant="standard"
              color="white"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
              }
            />
            {errors.email && (
              <p className="text-red-700">{`${errors.email.message}`}</p>
            )}
          </div>
          <Input
            {...register("password", {
              required: "Password is required",
              validate: validatePassword,
            })}
            type={isPasswordVisible ? "text" : "password"}
            size="lg"
            label="Password"
            variant="standard"
            color="white"
            icon={
              isPasswordVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="w-6 h-6"
                  onClick={handleShowPassword}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="w-6 h-6"
                  onClick={handleShowPassword}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              )
            }
            error={errors.password ? true : false}
          />
          {errors.password && (
            <p className="text-red-700">{`${errors.password.message}`}</p>
          )}
          <div className="my-8">
            <Input
              {...register("confirmPassword", {
                required: "Please confirm your password",
                validate: validateConfirmPassword,
              })}
              type={isConfirmPasswordVisible ? "text" : "password"}
              size="lg"
              label="Confirm Password"
              variant="standard"
              color="white"
              icon={
                isConfirmPasswordVisible ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="white"
                    className="w-6 h-6"
                    onClick={handleShowConfirmPassword}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="white"
                    className="w-6 h-6"
                    onClick={handleShowConfirmPassword}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )
              }
              error={errors.confirmPassword ? true : false}
            />
            {errors.confirmPassword && (
              <p className="text-red-700">
                {`${errors.confirmPassword.message}`}
              </p>
            )}
          </div>
          <Checkbox
            {...register("agreeTerms")}
            color="blue"
            label={
              <Typography variant="small" color="white">
                I agree to the
                <Typography
                  as="span"
                  variant="small"
                  color="white"
                  className="font-bold"
                >
                  Terms and Conditions
                </Typography>
              </Typography>
            }
          />
          <Button
            fullWidth
            className="bg-dark-primary-gradient mt-6 text-black text-lg md:text-xl px-6 py-2 shadow-md shadow-black"
            onClick={handleSubmit(handleSignUp)}
          >
            Sign Up
          </Button>
        </>
      );
    } else if (step === "CONFIRMATION") {
      return (
        <>
          <Typography variant="lead" color="white" className="mb-4 text-center">
            Please check your inbox at {getValues("email")} for your
            verification code.
          </Typography>
          <Input
            {...register("verificationCode", {
              required: "Please enter your verification code",
            })}
            type="text"
            size="lg"
            label="Verification Code"
            variant="standard"
            color="white"
          />
          <Button
            fullWidth
            className="bg-dark-primary-gradient mt-6 text-black text-lg md:text-xl px-6 py-2 shadow-md shadow-black"
            onClick={handleSubmit(verifyCode)}
          >
            Submit Code
          </Button>
        </>
      );
    }
  }

  async function handleSignUp(data: any) {
    const {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      userName,
      verificationCode,
      agreeTerms,
    } = data;

    try {
      const signUpResponse = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });
      setCognitoSub(signUpResponse.userId);
      // Change to confirmation code step
      setAlertType("success");
      setAlertMessage(
        "Sign up successful. Please check your email for the verification code."
      );
      setStep("CONFIRMATION");
    } catch (error: any) {
      if (error?.message === "User already exists") {
        // Display to user they already have an account
        setAlertType("error");
        setAlertMessage("User already exists. Redirecting to sign in page...");
        // Brief wait for them to see it
        setTimeout(() => {
          navigate("/sign-in");
        }, 1500);
      } else {
        console.error("Error during signup:", error);
        setAlertType("error");
        setAlertMessage("Failed to sign up");
        setError("email", { type: "manual", message: "Failed to sign up" });
      }
    }
  }

  // Function to handle confirmation code verification
  async function verifyCode(data: any) {
    try {
      const { email, verificationCode, userName, firstName, lastName } = data;
      const result = await confirmSignUp({
        username: email,
        confirmationCode: verificationCode,
      });
      // Sign in our user - TODO: group these api calls??
      // Create user in our DB
      const createdUser = await UserService.serviceCreateUser({
        username: userName,
        handle: userName?.toLowerCase(),
        firstName: firstName,
        lastName: lastName,
        email: email,
        subscriptionCost: 0,
        profilePicURI: "",
      });
      // Redirect user or update UI state to indicate a successful sign up
      setAlertType("success");
      setAlertMessage(
        "Verification successful. Redirecting to profile page..."
      );
      setTimeout(() => {
        navigate("/profile");
      }, 1500);
    } catch (error) {
      console.error("Confirmation error:", error);
      setAlertType("error");
      setAlertMessage("Failed to verify code");
      setError("verificationCode", {
        type: "manual",
        message: "Failed to verify code",
      });
    }
  }

  function validateConfirmPassword(value: string) {
    if (value !== getValues("password")) {
      return "Passwords do not match";
    }
    return true;
  }

  function handleShowPassword() {
    setPasswordVisible(!isPasswordVisible);
  }
  function handleShowConfirmPassword() {
    setConfirmPasswordVisible(!isConfirmPasswordVisible);
  }
}
