// PublicRoute.tsx
import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";

const PublicRoute = () => {
  const { fetchedUser, cognitoUser } = useAuthContext();
  return fetchedUser?.id && cognitoUser ? <Navigate to="/" /> : <Outlet />;
};

export default PublicRoute;
