import React from "react";
import { NavLink } from "react-router-dom";

const HomeHeader: React.FC = () => {
  return (
    <header className="flex justify-between items-center mb-4 md:p-4 relative">
      {/* Centered Navigation */}
      <div className="flex-grow">
        <nav className="flex justify-center gap-8 font-bold text-2xl md:pt-4">
          <NavLink
            to="/my-bets"
            className={({ isActive }) =>
              isActive
                ? "text-white underline decoration-white decoration-2"
                : "text-white hover:underline"
            }
          >
            My Bets
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "text-white underline decoration-white decoration-2"
                : "text-white hover:underline"
            }
          >
            Tailing
          </NavLink>
          <NavLink
            to="/popular"
            className={({ isActive }) =>
              isActive
                ? "text-white underline decoration-white decoration-2"
                : "text-white hover:underline"
            }
          >
            Popular
          </NavLink>
        </nav>
      </div>
      {/* Right Aligned Branding. It's now positioned absolutely relative to the header */}
    </header>
  );
};

export default HomeHeader;
