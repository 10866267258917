import { UploadDataWithPathInput, uploadData } from "aws-amplify/storage";

export const handleS3Upload = async (input: UploadDataWithPathInput) => {
  try {
    const result = uploadData({
      path: input.path,
      data: input.data,
      options: {
        onProgress: ({ transferredBytes, totalBytes }) => {
          if (totalBytes) {
            console.log(
              `Upload progress ${Math.round(
                (transferredBytes / totalBytes) * 100
              )} %`
            );
          }
        },
      },
    }).result;
    return result;
  } catch (error) {
    console.warn("Error uploading file : ", error);
  }
};
