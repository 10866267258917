import React from "react";
import { Link } from "react-router-dom";
import {
  SportsBar,
  PersonAdd,
  UploadFile,
  Star,
  HowToReg,
  AccountTree,
} from "@mui/icons-material";
import CapTailLogo from "../../assets/CapTailLogo.svg";
import { Typography, Button } from "@material-tailwind/react";
import { Container } from "@mui/material";
import FeatureCard from "components/cards/FeatureCard";
import TestimonialCard from "components/cards/TestimonialCard";

const HeaderSection = () => {
  return (
    <header className="flex justify-between py-4 px-4 bg-black text-white">
      <Link to={"/"}>
        <img src={CapTailLogo} alt="CapTail Logo" className="mx-8 w-32" />
      </Link>

      <nav className="flex space-x-4 text-lg">
        <a href="#features" className="hover:text-dark-orange">
          Features
        </a>
        <a href="#how-it-works" className="hover:text-dark-orange">
          How It Works
        </a>
        <a href="#testimonials" className="hover:text-dark-orange">
          Testimonials
        </a>
        <a href="#contact" className="hover:text-dark-orange">
          Contact
        </a>
      </nav>
      <div className="flex space-x-4">
        <Link to="/sign-in">
          <Button className="bg-white text-black text-md px-4 py-2 rounded">
            Sign In
          </Button>
        </Link>
        <Link to="/sign-up">
          <Button className="bg-dark-orange text-white text-md px-4 py-2 rounded">
            Sign Up
          </Button>
        </Link>
      </div>
    </header>
  );
};

const HeroSection = () => {
  return (
    <section className="flex flex-col items-center justify-center h-screen bg-hero-pattern text-white text-center">
      <h1 className="text-5xl font-bold mb-4">
        Your Premier Social Sports Betting Community
      </h1>
      <p className="text-xl mb-8">
        Follow expert cappers, replicate their winning bets, let AI upload your
        bets, and start your own capping journey.
      </p>
      <Link to="/profile/sign-up">
        <Button
          size="lg"
          className="bg-black px-6 py-4 shadow-md shadow-white text-lg"
        >
          Get Started
        </Button>
      </Link>
    </section>
  );
};

const FeaturesSection = () => {
  return (
    <section id="features" className="py-16 ">
      <Container>
        <Typography
          variant="h2"
          color="gray"
          className="font-bold mb-8 text-center text-black"
        >
          Why Choose CapTail?
        </Typography>
        <div className="flex flex-wrap justify-center">
          <FeatureCard
            Icon={PersonAdd}
            title="Subscribe to Cappers"
            description="Follow top cappers and get notified about their latest bets."
          />
          <FeatureCard
            Icon={SportsBar}
            title="Tail Bets"
            description="Easily tail the bets of your favorite cappers and increase your chances of winning."
          />
          <FeatureCard
            Icon={UploadFile}
            title="Upload Bets with AI"
            description="Use our AI technology to upload and analyze your bet slips."
          />
          <FeatureCard
            Icon={Star}
            title="Become a Capper"
            description="Set your own fee and let others subscribe to your bets."
          />
        </div>
      </Container>
    </section>
  );
};

const HowItWorksSection: React.FC = () => {
  return (
    <section id="how-it-works" className="py-16">
      <Container className="mx-auto text-center">
        <Typography variant="h2" className="text-3xl font-bold mb-8">
          How CapTail Works
        </Typography>
        <div className="flex flex-wrap justify-center">
          <FeatureCard
            Icon={HowToReg}
            title="Sign Up"
            description="Create your account to get started."
          />
          <FeatureCard
            Icon={UploadFile}
            title="Upload Bets"
            description="Upload your bet slips and let us do the rest."
          />
          <FeatureCard
            Icon={AccountTree}
            title="Tail Bets"
            description="Follow and tail bets from your favorite cappers."
          />
        </div>
      </Container>
    </section>
  );
};

// TODO: find a way to make this programmatic... google reviews?
const TestimonialsSection: React.FC = () => {
  return (
    <section id="testimonials" className="py-16 ">
      <Container className="mx-auto text-center">
        <Typography variant="h2" className="text-3xl font-bold mb-8 text-white">
          What Our Users Say
        </Typography>
        <div className="flex flex-wrap justify-center">
          <TestimonialCard
            image="/user1.jpg"
            name="John Doe"
            feedback="“CapTail has revolutionized the way I track and analyze my bets. Highly recommend!”"
          />
          <TestimonialCard
            image="/user2.jpg"
            name="Jane Smith"
            feedback="“The insights and analytics provided by CapTail are top-notch. It’s a must-have for any serious bettor.”"
          />
          {/* Add more TestimonialCards as needed */}
        </div>
      </Container>
    </section>
  );
};

const CtaSection = () => {
  return (
    <section id="cta" className="py-16 text-white text-center">
      <h2 className="text-4xl font-bold mb-4">Ready to Get Started?</h2>
      <p className="text-xl mb-8">
        Join CapTail today and take your betting to the next level.
      </p>
      <Link to="/sign-up">
        <Button className="bg-white text-dark-orange px-8 py-4 rounded-lg text-lg font-bold">
          Sign Up Now
        </Button>
      </Link>
    </section>
  );
};

const LandingPage = () => {
  return (
    <div>
      <HeaderSection />
      <HeroSection />
      <FeaturesSection />
      <HowItWorksSection />
      <TestimonialsSection />
      <CtaSection />
    </div>
  );
};

export default LandingPage;
