import CapperCard from "components/cards/CapperCard";
import { useEffect, useState } from "react";
import SearchHeader from "./header";
import SearchBar from "components/Searchbar";
import { searchUsers } from "services/UserService";
import { useDataStoreListener } from "hooks/useDataStoreListener";
import { Schema } from "../../../amplify/data/resource";
import { useAuthContext } from "context/AuthContext";

function SearchScreen() {
  const { fetchedUser } = useAuthContext();
  const [fetchedUsers, setFetchedUsers] = useState<Schema["User"]["type"][]>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearch = async () => {
    const foundUsers = await searchUsers(searchTerm, fetchedUser?.id);
    setFetchedUsers(foundUsers);
  };

  useEffect(() => {
    handleSearch();
    if (fetchedUsers?.length) {
      unSubscribe();
    }
  }, [searchTerm]);

  const { unSubscribe } = useDataStoreListener(() => {
    if (fetchedUsers?.length === 0) {
      handleSearch();
    } else {
      unSubscribe();
    }
  });

  return (
    <div className="lg:my-4 px-8 md:px-24 ">
      <SearchHeader />
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <div className="mt-4 h-[calc(90vh)] overflow-y-auto">
        {fetchedUsers?.map((user, index) => (
          <div className="my-8" key={user.id}>
            <CapperCard
              id={user.id}
              username={user.username}
              handle={user.handle}
              profilePictureURL={user.profilePicURI}
              // bestTeam={"Washington Wizards"}
              // bestTeamImageURL={"https://picsum.photos/200"}
              // bestTeamWinPercentage={Math.random() * 100}
              monthlySubscriptionCost={user.subscriptionCost}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SearchScreen;
