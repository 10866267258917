// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SportsLeague = {
  "FIFA_WORLD_CUP": "FIFA_WORLD_CUP",
  "UEFA_CHAMPIONS_LEAGUE": "UEFA_CHAMPIONS_LEAGUE",
  "UEFA_EUROPA_LEAGUE": "UEFA_EUROPA_LEAGUE",
  "ENGLISH_PREMIER_LEAGUE": "ENGLISH_PREMIER_LEAGUE",
  "SPANISH_LA_LIGA": "SPANISH_LA_LIGA",
  "ITALIAN_SERIE_A": "ITALIAN_SERIE_A",
  "GERMAN_BUNDESLIGA": "GERMAN_BUNDESLIGA",
  "FRENCH_LIGUE_1": "FRENCH_LIGUE_1",
  "PORTUGUESE_PRIMEIRA_LIGA": "PORTUGUESE_PRIMEIRA_LIGA",
  "DUTCH_EREDIVISIE": "DUTCH_EREDIVISIE",
  "BRAZILIAN_SERIE_A": "BRAZILIAN_SERIE_A",
  "ARGENTINE_PRIMERA_DIVISION": "ARGENTINE_PRIMERA_DIVISION",
  "MLS": "MLS",
  "NFL": "NFL",
  "CFL": "CFL",
  "NCAA_FOOTBALL": "NCAA_FOOTBALL",
  "NBA": "NBA",
  "NCAA_BASKETBALL": "NCAA_BASKETBALL",
  "EUROLEAGUE_BASKETBALL": "EUROLEAGUE_BASKETBALL",
  "FIBA_WORLD_CUP": "FIBA_WORLD_CUP",
  "MLB": "MLB",
  "NPB": "NPB",
  "KBO": "KBO",
  "NHL": "NHL",
  "KHL": "KHL",
  "SHL": "SHL",
  "ICC_CRICKET_WORLD_CUP": "ICC_CRICKET_WORLD_CUP",
  "IPL": "IPL",
  "BIG_BASH_LEAGUE": "BIG_BASH_LEAGUE",
  "CARIBBEAN_PREMIER_LEAGUE": "CARIBBEAN_PREMIER_LEAGUE",
  "ATP": "ATP",
  "WTA": "WTA",
  "GRAND_SLAM": "GRAND_SLAM",
  "PGA_TOUR": "PGA_TOUR",
  "EUROPEAN_TOUR": "EUROPEAN_TOUR",
  "LPGA_TOUR": "LPGA_TOUR",
  "FORMULA_1": "FORMULA_1",
  "NASCAR": "NASCAR",
  "MOTOGP": "MOTOGP",
  "RUGBY_WORLD_CUP": "RUGBY_WORLD_CUP",
  "SIX_NATIONS": "SIX_NATIONS",
  "SUPER_RUGBY": "SUPER_RUGBY",
  "UFC": "UFC",
  "BOXING": "BOXING",
  "OLYMPICS": "OLYMPICS"
};

const SubscriptionPlan = {
  "MONTHLY": "MONTHLY",
  "QUARTERLY": "QUARTERLY",
  "YEARLY": "YEARLY"
};

const SubscriptionStatus = {
  "ACTIVE": "ACTIVE",
  "PENDING": "PENDING",
  "CANCELLED": "CANCELLED",
  "EXPIRED": "EXPIRED"
};

const BetType = {
  "PARLAY": "PARLAY",
  "SINGLE": "SINGLE"
};

const BetResult = {
  "WON": "WON",
  "LOST": "LOST",
  "PENDING": "PENDING"
};

const UserRole = {
  "ADMIN": "ADMIN",
  "USER": "USER",
  "PREMIUM_USER": "PREMIUM_USER"
};

const ReactionType = {
  "LIKE": "LIKE",
  "DISLIKE": "DISLIKE"
};

const TransactionType = {
  "SUBSCRIPTION": "SUBSCRIPTION",
  "OTHER": "OTHER"
};

const BetEntity = {
  "PLAYER": "PLAYER",
  "TEAM": "TEAM"
};

const { Bet, TailedBet, User, UserFollowing, Team, Comment, Reaction, Transaction, BetSlipOCRResult, Player, BetLeg } = initSchema(schema);

export {
  Bet,
  TailedBet,
  User,
  UserFollowing,
  Team,
  Comment,
  Reaction,
  Transaction,
  SportsLeague,
  SubscriptionPlan,
  SubscriptionStatus,
  BetType,
  BetResult,
  UserRole,
  ReactionType,
  TransactionType,
  BetEntity,
  BetSlipOCRResult,
  Player,
  BetLeg
};