import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import CapTailLogo from "../../assets/CapTailLogo.svg"
import { useAuthContext } from "context/AuthContext";
import { navItems, SignOutButton } from "./NavItems";

const DesktopNavBar: React.FC = () => {
  const location = useLocation();
  const { fetchedUser } = useAuthContext();

  // Function to determine if the current path is active
  const isActive = (to: string, subPaths?: string[]) => {
    if (location.pathname === to) return true;
    if (subPaths && subPaths.includes(location.pathname)) return true;
    if (to !== "/" && location.pathname.startsWith(to)) return true;
    return false;
  };

  return (
    <nav className="h-full p-5 space-y-6">
      <div className="text-center">
        <Link to={"/"}>
          <img
            src={CapTailLogo}
            alt="Capital Logo"
            className="mx-auto h-32 w-32"
          />
        </Link>
        {/* Adjust the height and width as needed */}
      </div>
      <div className="text-center text-white space-y-6">
        {navItems.map((item: any) => {
          const Icon = item.icon; // Get the icon component
          const activeClass = isActive(item.to, item.subPaths)
            ? "text-white"
            : "text-black";
          return (
            <NavLink
              key={item.name}
              to={item.to}
              className={`block hover:text-primary ${activeClass}`}
            >
              <Icon
                className={`mx-auto ${activeClass}`}
                style={{ fontSize: "32px" }} // Ensure fontSize unit is specified correctly
              />
              <p className={activeClass}>{item.name}</p>
            </NavLink>
          );
        })}
        {fetchedUser && (
          <div className="flex justify-center">
            <SignOutButton fetchedUser={fetchedUser} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default DesktopNavBar;
