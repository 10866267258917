import React from "react";
import { Typography, Card } from "@material-tailwind/react";

interface TestimonialCardProps {
  image: string;
  name: string;
  feedback: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  image,
  name,
  feedback,
}) => {
  return (
    <Card className="w-full md:w-1/4 p-6 m-4 bg-black border-2 border-dark-orange rounded-lg shadow-lg transition-transform transform hover:scale-105">
      <img
        src={image}
        alt={name}
        className="h-16 w-16 rounded-full mb-4 mx-auto"
      />
      <Typography variant="h5" className="font-bold mb-2 text-gray-300">
        {name}
      </Typography>
      <Typography className="text-gray-400">{feedback}</Typography>
    </Card>
  );
};

export default TestimonialCard;
