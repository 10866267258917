/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
  Select,
  Option,
  Input,
} from "@material-tailwind/react";
import { useAuthContext } from "context/AuthContext";
import { createTailedBet } from "services/TailedBetService";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface TailBetModalProps {
  isOpen: boolean;
  onClose: () => void;
  betDetails: {
    betID: string;
    username: string;
    handle: string;
    profilePictureURI?: string;
    betDate: string;
    odds: number;
    wager: number;
    result?: "WON" | "LOST" | "ACTIVE";
    active?: boolean;
  };
}
const wagerUnitOptions = [
  "0.5U",
  "1U",
  "1.5U",
  "2U",
  "2.5U",
  "3U",
  "3.5U",
  "4U",
  "4.5U",
  "5U",
];

const TailBetModal: React.FC<TailBetModalProps> = ({
  isOpen,
  onClose,
  betDetails,
}) => {
  const { fetchedUser } = useAuthContext();
  const [userWager, setUserWager] = useState<number>(0);
  const priorityText = "font-bold text-lg text-black";

  const handleTailBet = async () => {
    if (!fetchedUser?.id) return;
    await createTailedBet({
      userID: fetchedUser?.id,
      wager: userWager,
      betID: betDetails.betID,
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} handler={onClose} size="sm">
      <DialogHeader className="flex justify-between">
        <div className="flex items-center space-x-4">
          {betDetails.profilePictureURI ? (
            <StorageImage
              alt={`${betDetails.username} picture`}
              path={betDetails.profilePictureURI}
              className="rounded-full h-12 w-12"
            />
          ) : (
            <img className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-md shadow-black flex items-center justify-center cursor-pointer bg-gray-500">
              <AccountCircleIcon className="h-12 w-12 text-white" />
            </img>
          )}
          <div>
            <h2 className="font-bold text-lg">{betDetails.username}</h2>
            <p className="text-sm">@{betDetails.handle}</p>
          </div>
        </div>
        <Button
          variant="text"
          color="gray"
          onClick={onClose}
          className="text-xl"
        >
          ×
        </Button>
      </DialogHeader>
      <DialogBody className="text-center">
        <p className={priorityText}>Odds: ({betDetails.odds})</p>
        <p className={"mb-4 " + priorityText}>
          Capper's Wager: {betDetails.wager}U
        </p>
        <div className="flex flex-col lg:flex-row align-center items-center space-x-2 mx-32">
          <div className="flex items-center justify-center bg-black text-white border border-gray-300 rounded-lg px-4 py-2 min-w-[50]">
            My Wager
          </div>
          <div className="bg-white w-px h-6" />
          <Select
            defaultValue={betDetails.wager}
            disabled={!fetchedUser?.id}
            variant="outlined"
            size="lg"
            className="bg-black text-white text-center border border-gray-300 rounded-lg"
          >
            {wagerUnitOptions.map((unit) => (
              <Option
                key={unit}
                value={unit}
                className="bg-black text-white highlight:black"
              >
                {unit}
              </Option>
            ))}
          </Select>
        </div>
        <p className="mt-4 font-black">
          Are you sure you want to tail this bet? It will be permanently added
          to your bet history.
        </p>
      </DialogBody>
      <DialogFooter className="justify-center">
        <Button
          disabled={!fetchedUser?.id}
          size="lg"
          className="bg-dark-primary-gradient px-24 shadow-lg shadow-black"
          onClick={handleTailBet}
        >
          {fetchedUser?.id ? "Tail" : "Login to Tail"}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default TailBetModal;
