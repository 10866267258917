import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { confirmSignUp, resendSignUpCode, signIn } from "aws-amplify/auth";
import {
  Input,
  Button,
  Card,
  Typography,
  Alert,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { validateEmail } from "utils/validate";

export default function SignIn() {
  const [step, setStep] = useState<"SIGN-IN" | "CONFIRMATION">("SIGN-IN");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      verificationCode: "",
    },
  });

  const handleErrorClose = () => {
    setErrorMessage(null);
  };

  return (
    <div className="flex items-center justify-center md:min-h-screen md:min-h my-24 md:my-0">
      <Card className="w-full max-w-sm md:max-w-xl p-4 bg-opacity-60 bg-black border-2 border-mid-purple">
        <Typography
          variant="h5"
          className="mb-4 md:mb-8 text-center text-white"
        >
          Sign In
        </Typography>
        {errorMessage && (
          <Alert color="red" onClose={handleErrorClose} className="mb-4">
            {errorMessage}
          </Alert>
        )}
        <form onSubmit={handleSubmit(handleSignIn)}>{renderForm()}</form>
        <div className="flex justify-between items-center mt-4 space-x-4">
          <Link to={"/forgot-password"}>
            <Button type="button" variant="text" className="text-white">
              Forgot password?
            </Button>
          </Link>
          <Link to={"/sign-up"}>
            <Button type="button" variant="text" className="text-white">
              Don't have an account? Sign Up
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  );

  function renderForm() {
    if (step === "SIGN-IN") {
      return (
        <>
          <Input
            type="email"
            size="lg"
            label="Email"
            color="white"
            placeholder="Email Address"
            className="text-base md:text-xl"
            {...register("email", {
              required: "Email is required",
              validate: validateEmail,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
            error={errors.email ? true : false}
          />
          <div className="my-4 md:my-12">
            <Input
              type="password"
              size="lg"
              label="Password"
              color="white"
              placeholder="Password"
              className="text-base md:text-xl"
              {...register("password", { required: "Password is required" })}
              error={errors.password ? true : false}
            />
          </div>
          <Button
            type="submit"
            fullWidth={true}
            className="bg-dark-primary-gradient text-white text-lg md:text-xl px-6 py-2 my-12 shadow-md shadow-black"
          >
            Sign In
          </Button>
        </>
      );
    } else if (step === "CONFIRMATION") {
      return (
        <>
          <Typography
            variant="h6"
            className="mb-4 md:mb-8 text-center text-white"
          >
            Please enter the verification code sent to {getValues("email")}
          </Typography>
          <Input
            {...register("verificationCode", {
              required: "Please enter your verification code",
            })}
            type="number"
            size="lg"
            label="Verification Code"
            variant="standard"
            color="white"
          />
          <Button
            fullWidth
            className="bg-dark-primary-gradient mt-6 text-black text-lg md:text-xl px-6 py-2 shadow-md shadow-black"
            // onClick={handleSubmit(verifyCode)}
          >
            Submit Code
          </Button>
        </>
      );
    }
  }

  // async function verifyCode(data: any) {
  //   try {
  //     console.log("Verifying code:", data);
  //     const { email, username, firstname, lastname, verificationCode } = data;
  //     const result = await confirmSignUp({
  //       username: email,
  //       confirmationCode: verificationCode,
  //     });
  //     console.log("Confirmation success:", result);
  //     if (result.isSignUpComplete) {
  //       await Promise.all([
  //         UserService.serviceCreateUser({
  //           email: email,
  //           firstName: firstname,
  //           handle: username.toLowerCase(),
  //           lastName: lastname,
  //           phoneNumber:,
  //           profilePicURI: "",
  //           subscriptionCost: 0,
  //           username: "",
  //         }),
  //         handleSignIn(data),
  //       ]);
  //     }
  //   } catch (error) {
  //     console.error("Confirmation error:", error);
  //     setError("verificationCode", {
  //       type: "manual",
  //       message: "Failed to verify code",
  //     });
  //     setErrorMessage("Failed to verify code");
  //   }
  // }

  async function handleSignIn(data: any) {
    try {
      const { email, password } = data;
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password: password,
      });
      if (nextStep.signInStep === "DONE") {
        return;
      }
      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        await resendSignUpCode({
          username: email,
        });
        setStep("CONFIRMATION");
      }
    } catch (error: any) {
      console.error("Error signing in:", error);
      setErrorMessage(`Error: ${error?.message}`);
    }
  }
}
