import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import colors from "theme/colors";
import CapTailLogoPNG from "../../assets/CapTailLogo.png";
import { useAuthContext } from "context/AuthContext";
import { navItems, SignOutButton } from "./NavItems";

const MobileNavBar = () => {
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const { fetchedUser } = useAuthContext();

  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        setIsNavOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  const isActive = (to: string, subPaths?: string[]) => {
    if (location.pathname === to) return true;
    if (subPaths && subPaths.includes(location.pathname)) return true;
    if (to !== "/" && location.pathname.startsWith(to)) return true;
    return false;
  };

  return (
    <div>
      <button className="p-4 text-xl md:hidden z-40 " onClick={toggleNavbar}>
        <MenuIcon
          style={{ fontSize: "32px" }}
          htmlColor={isNavOpen ? "transparent" : colors.white}
        />
      </button>
      <nav
        ref={navRef}
        className={`fixed inset-y-0 left-0 transform md:transform-none md:relative z-30 w-1/3 overflow-auto transition-transform duration-500 ${
          isNavOpen
            ? "translate-x-0 bg-reverse-selection-gradient"
            : "-translate-x-full"
        } md:translate-x-0 w-400 bg-transparent opacity-100 md:opacity-0 flex flex-col justify-center`}
      >
        <div className="text-center text-white space-y-6 flex-grow flex flex-col mt-40">
          {navItems.map((item: any) => {
            const Icon = item.icon;
            const activeClass = isActive(item.to, item.subPaths)
              ? "text-primary"
              : "";
            return (
              <NavLink
                key={item.name}
                to={item.to}
                className={`block hover:text-primary ${activeClass}`}
              >
                <Icon style={{ fontSize: "32px" }} />
                <p className={activeClass}>{item.name}</p>
              </NavLink>
            );
          })}
          {fetchedUser && (
            <div className="flex justify-center">
              <SignOutButton fetchedUser={fetchedUser} />
            </div>
          )}
        </div>
        <Link
          to={"/"}
          className="text-center text-white text-xl self-center mx-auto absolute bottom-4 right-2 left-2"
        >
          <img src={CapTailLogoPNG} alt="Capital Logo" className="mx-auto " />
        </Link>
      </nav>
    </div>
  );
};

export default MobileNavBar;
