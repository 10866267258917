import React, { useState } from "react";
import { Avatar, IconButton } from "@material-tailwind/react";
import BetModal from "components/modals/TailBetModal";
import BetLegCard from "./BetLegCard";
import { formatWagerWinnings } from "utils/format/formatPrice";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthContext } from "context/AuthContext";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export interface BetCardProps {
  userID: string;
  betID: string;
  username: string;
  handle: string;
  profilePictureURI?: string;
  betDate: string;
  odds: number;
  wager: number;
  active: boolean;
  tailing: boolean;
  tailedWager?: number;
  result?: any | null;
  legs?: any[];
}

const BetCard: React.FC<BetCardProps> = ({
  userID,
  betID,
  username,
  handle,
  profilePictureURI,
  betDate,
  odds,
  wager,
  active,
  tailing,
  tailedWager,
  result,
  legs,
}) => {
  const { fetchedUser } = useAuthContext();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const priorityText = "font-bold text-lg";

  const cardColor =
    (result === "WON" && "bg-green-500") ||
    (result === "LOST" && "bg-red-500") ||
    "bg-card-gradient";

  const handleModal = () => setModalOpen(!isModalOpen);

  const editable = userID === fetchedUser?.id && active;

  return (
    <div className={"mt-2.5 rounded-lg overflow-hidden shadow-lg " + cardColor}>
      <div className="flex flex-col p-2.5 justify-between items-center lg:items-stretch">
        {/* Top Section: Avatar, User Info and Actions */}
        <div className="flex flex-row lg:flex-row justify-between items-center w-full">
          <div className="flex flex-col lg:flex-row lg:items-end ">
            {profilePictureURI ? (
              <StorageImage
                alt={`${username} picture`}
                path={profilePictureURI}
                className="h-12 w-12"
              />
            ) : (
              <img className="w-24 h-24 md:w-32 md:h-32 rounded-full shadow-md shadow-black flex items-center justify-center cursor-pointer bg-gray-500">
                <AccountCircleIcon className="h-12 w-12 text-white" />
              </img>
            )}
            <div className={priorityText}>@{handle}</div>
          </div>

          <div className="mt-4 lg:mt-0 lg:ml-4 flex items-center space-x-4">
            <IconButton
              onClick={handleModal}
              className="bg-light-primary-gradient shadow-md shadow-black"
              size="lg"
              disabled={tailing}
            >
              <p className="text-sm">{tailing ? "Tailed" : "Tail"}</p>
            </IconButton>
          </div>
        </div>

        {/* Middle Section: League and Odds */}
        <div className="flex justify-center lg:mt-2">
          <div className="flex items-center space-x-2">
            <p className={priorityText}>{odds > 0 ? `+${odds}` : odds}</p>
          </div>
        </div>
      </div>

      {/* Lower Section: Bet Legs and Wager Info */}
      <div className="p-2.5">
        {legs?.map((leg, index) => (
          <BetLegCard key={index} betLeg={leg} date={leg.date} />
        ))}
        <div className="flex justify-between mt-4">
          <p>{`${
            tailing && tailedWager ? "Capper's Wager:" : "Wager:"
          } ${wager}U`}</p>
          <p>To Win: {formatWagerWinnings(wager, odds)}U</p>
          {/* TODO: implement this edit function */}
          {editable && (
            <button onClick={() => console.log("edit")}>
              <EditIcon />
            </button>
          )}
        </div>
        {tailing && tailedWager !== undefined && (
          <div className="flex justify-between mt-4 font-bold">
            <p>Your Wager: {tailedWager}U</p>
            <p>Your To Win: {formatWagerWinnings(tailedWager, odds)}U</p>
          </div>
        )}
      </div>

      {/* BetModal */}
      {isModalOpen && (
        <BetModal
          isOpen={isModalOpen}
          onClose={handleModal}
          betDetails={{
            betID,
            username,
            handle,
            profilePictureURI,
            betDate,
            odds,
            wager,
          }}
        />
      )}
    </div>
  );
};

export default BetCard;
