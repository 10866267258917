import { UserFollowing } from "models";
import { DataStore } from "aws-amplify/datastore";

export const getUserFollowingByID = async (userFollowingID: string) => {
  try {
    const foundUserFollowing = await DataStore.query(
      UserFollowing,
      userFollowingID
    );
    return foundUserFollowing as UserFollowing;
  } catch (err) {
    console.warn("error: UserFollowingService getUserFollowingByID", err);
    return {} as UserFollowing;
  }
};
// TODO: find out where we can type this input
export const serviceCreateUserFollowing = async (newUserFollowing: any) => {
  try {
    // Check if the user is already following the provider
    const existingFollowings = await DataStore.query(UserFollowing, (uf) =>
      uf.and((uf) => [
        uf.subscriberID.eq(newUserFollowing.subscriberID),
        uf.providerID.eq(newUserFollowing.providerID),
      ])
    );

    if (existingFollowings.length > 0) {
      throw new Error("You are already following this user.");
    }

    // Create the new user following relationship
    const createdUserFollowing: any = await DataStore.save(
      new UserFollowing({
        ...newUserFollowing,
      })
    );

    return createdUserFollowing as UserFollowing;
  } catch (err: any) {
    console.warn(
      "error: UserFollowingService serviceCreateUserFollowing",
      err,
      newUserFollowing
    );
    throw Error(err?.message);
  }
};

// TODO: find out where we can type this input
export const updateUserFollowing = async (updatedUserFollowing: any) => {
  try {
    const existingUserFollowing = await DataStore.query(
      UserFollowing,
      updatedUserFollowing.id
    );

    if (!existingUserFollowing) {
      throw new Error("UserFollowing not found");
    }

    const updatedUserFollowingData = await DataStore.save(
      UserFollowing.copyOf(existingUserFollowing, (updated) => {
        Object.assign(updated, updatedUserFollowing);
      })
    );

    return updatedUserFollowingData;
  } catch (err) {
    console.warn(
      "error: UserFollowingService updateUserFollowing",
      err,
      updatedUserFollowing
    );
    throw err;
  }
};

export const searchUserFollowings = async (searchText: string) => {
  try {
    const fetchedUserFollowings = await DataStore.query(UserFollowing, (uf) => {
      return uf.or((uf) => [
        uf.subscriberID.contains(searchText),
        uf.providerID.contains(searchText),
      ]);
    });
    return fetchedUserFollowings;
  } catch (err) {
    console.warn(
      "error: UserFollowingService searchUserFollowings",
      err,
      searchText
    );
    return [];
  }
};

export const fetchAllUserFollowings = async () => {
  try {
    const fetchedUserFollowings = await DataStore.query(UserFollowing);
    return fetchedUserFollowings;
  } catch (err) {
    console.warn("error: UserFollowingService fetchAllUserFollowings", err);
    return [];
  }
};

export const subscribeToUserFollowingUpdates = (
  userFollowingID: string,
  callback: (userFollowing: UserFollowing | null) => void
) => {
  try {
    return DataStore.observeQuery(UserFollowing, (uf) =>
      uf.id.eq(userFollowingID)
    ).subscribe({
      next: ({ items }) => {
        if (items.length > 0) {
          callback(items[0]);
        } else {
          callback(null);
        }
      },
      error: (error) => {
        console.warn("observeQuery error", error);
        callback(null);
      },
    });
  } catch (error) {
    console.warn("subscribeToUserFollowingUpdates error", error);
    callback(null);
  }
};

export const serviceUnsubscribeUser = async (
  subscriberID: string,
  providerID: string
) => {
  try {
    const userFollowingRecords = await DataStore.query(UserFollowing, (uf) =>
      uf.and((uf) => [
        uf.subscriberID.eq(subscriberID),
        uf.providerID.eq(providerID),
      ])
    );

    if (userFollowingRecords.length > 0) {
      const userFollowingRecord = userFollowingRecords[0];
      await DataStore.save(
        UserFollowing.copyOf(userFollowingRecord, (updated) => {
          updated.subscriptionStatus = "CANCELLED";
        })
      );
      await DataStore.delete(userFollowingRecord);
    } else {
      throw new Error("Subscription record not found");
    }
  } catch (err) {
    console.warn("error: UserFollowingService serviceUnsubscribeUser", err);
    throw err;
  }
};
