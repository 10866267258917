import { useEffect, useRef } from "react";
import { Hub } from "aws-amplify/utils";

export function useDataStoreListener(callback: () => void) {
  const subscriptionRef = useRef<any>(null);

  useEffect(() => {
    // Subscribe to datastore updates
    const dataStoreListener = Hub.listen("datastore", (hubData) => {
      if (hubData.payload.event === "modelSynced") {
        callback();
      }
    });

    subscriptionRef.current = dataStoreListener;

    return () => {
      if (subscriptionRef.current) {
        dataStoreListener();
      }
    };
  }, [callback]);

  const unSubscribe = () => {
    if (subscriptionRef.current) {
      subscriptionRef.current();
      subscriptionRef.current = null;
    }
  };

  return { unSubscribe };
}