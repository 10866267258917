import { BetService, TailedBetService } from "services";
import { startOfWeek, addWeeks, format } from "date-fns";
import { BetLeg } from "models";
import { Schema } from "../../../amplify/data/resource";

export const calculateTotalOdds = (
  legs: Schema["Bet"]["type"]["legs"]
): number => {
  if (legs.length === 0) return 0;
  const decimalOdds = legs.map((leg) => {
    if (leg && leg.odds) {
      return leg.odds > 0 ? leg.odds / 100 + 1 : 100 / Math.abs(leg.odds) + 1;
    }
    return 1;
  });
  const totalDecimalOdds = decimalOdds.reduce((acc, odd) => acc * odd, 1);
  return totalDecimalOdds > 2
    ? Math.round((totalDecimalOdds - 1) * 100)
    : Math.round(-100 / (totalDecimalOdds - 1));
};

export const calculateToWin = (wager: number, totalOdds: number): number => {
  if (totalOdds > 0) {
    return wager * (totalOdds / 100);
  } else {
    return wager / (Math.abs(totalOdds) / 100);
  }
};

export async function calculateTotalEarningsForUser(userId: string) {
  try {
    // Assuming fetchUserBets only fetches settled bets (isActive: false)
    const bets = await BetService.fetchUserBetsWithFilters(userId, false);

    // Filter and sum only the wagers of won bets
    const totalEarnings = bets.reduce((acc, { bet }) => {
      if (bet.result === "WIN") {
        return acc + bet.wager; // Only add wager if the bet was won
      }
      return acc; // If not won, just return the accumulated total
    }, 0);

    return totalEarnings;
  } catch (error) {
    console.error("Error calculating total earnings:", error);
  }
}

export async function getEarningsByWeek(
  userId: string
): Promise<{ x: string; y: number }[]> {
  const threeMonthsAgo = addWeeks(new Date(), -12);
  const userBets = await BetService.fetchUserBetsSince(userId, threeMonthsAgo);
  const tailedBets = await TailedBetService.fetchTailedBetsSince(
    userId,
    threeMonthsAgo
  );

  let weeklyEarnings: Record<string, number> = {}; // Explicitly typing the object

  const accumulateEarnings = (bet: Schema["Bet"]["type"], wager: number) => {
    if (bet.result === "WIN") {
      const weekStart = format(
        startOfWeek(new Date(bet?.createdAt!)),
        "yyyy-MM-dd"
      );
      weeklyEarnings[weekStart] = (weeklyEarnings[weekStart] || 0) + wager;
    }
  };

  userBets.forEach(({ bet }) => accumulateEarnings(bet, bet.wager));
  tailedBets.forEach(({ bet }) => accumulateEarnings(bet, bet.wager)); // Assuming wager is the amount the user bet when tailing

  return Object.entries(weeklyEarnings).map(([week, earnings]) => ({
    x: week,
    y: earnings,
  }));
}

export const calculateCombinedOdds = (legs: BetLeg[]) => {
  const decimalOdds = legs.map((leg) => {
    const odds = leg.odds; // Cast to any for dynamic access

    if (odds === undefined) {
      throw new Error(`Odds not found for leg type: ${leg.type}`);
    }

    if (odds > 0) {
      return odds / 100 + 1;
    } else {
      return 100 / Math.abs(odds) + 1;
    }
  });

  const combinedDecimalOdds = decimalOdds.reduce((acc, cur) => acc * cur, 1);

  if (combinedDecimalOdds >= 2) {
    return (combinedDecimalOdds - 1) * 100;
  } else {
    return -100 / (combinedDecimalOdds - 1);
  }
};
