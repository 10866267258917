import React, { useState } from "react";
import { Typography, Card, Alert } from "@material-tailwind/react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { v4 as uuidv4 } from "uuid";
import { handleS3Upload } from "utils/helper/s3Upload";
import { useAuthContext } from "context/AuthContext";
import { getPresignedURL } from "utils/helper/s3PresignedUrl";
import { processBetSlip } from "services/BetService";
import Spinner from "components/Spinner";
import BetSlipModal from "components/modals/BetSlipModal";

function BetScreen() {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const [ocrResult, setOcrResult] = useState<any>(null); // Changed to store object
  const { fetchedUser } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      try {
        if (!fetchedUser)
          throw new Error("You must be signed in to upload a bet");

        const files = Array.from(event.target.files);
        setIsUploading(true);
        const uploadDate = new Date().toISOString();
        // Upload files and get presigned URLs
        const presignedURLs = await Promise.all(
          files.map(async (file, index) => {
            const fileKey = `${uploadDate}/${index}-${file.name}`;
            const s3result = await handleS3Upload({
              path: `public/bet-uploads/${fetchedUser.id}/${fileKey}`,
              data: file,
            });

            if (!s3result) {
              throw new Error(
                "Sorry, we ran into an issue uploading your image."
              );
            }

            const presignedURL = await getPresignedURL(s3result.path);
            return presignedURL.url.toString();
          })
        );

        // Process bet slip with all presigned URLs
        const ocrResult = await processBetSlip({
          data: presignedURLs,
        });

        if (ocrResult?.body) {
          const parsedResult = JSON.parse(ocrResult.body as any);
          setOcrResult(parsedResult);
          setUploadMessage("Bet slips processed successfully!");
          setIsModalOpen(true);
        }
      } catch (error) {
        setUploadError(true);
        setUploadMessage("Failed to process bet slips. Please try again.");
        console.error("Error processing files:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div className="flex flex-col items-center md:justify-center py-4 px-4 sm:px-6 lg:px-8 h-screen overflow-auto overscroll-y-auto">
      <Typography
        variant="h3"
        color="white"
        className="font-bold mb-8 text-center"
      >
        Create a Bet
      </Typography>
      <Card className="w-full max-w-lg p-6 flex flex-col items-center bg-modal-black bg-opacity-75 shadow-white shadow-lg">
        <Typography
          variant="h6"
          color="white"
          className="text-center mb-4 font-bold"
        >
          To create a bet, simply upload any screenshot(s) of the desired bet
          slip!
        </Typography>
        <label className="w-full h-40 border-2 border-dashed border-white flex items-center justify-center rounded-lg cursor-pointer hover:bg-opacity-10 transition-all duration-300">
          <input
            disabled={!fetchedUser?.id}
            type="file"
            multiple
            accept="image/*"
            className="hidden"
            onChange={handleFileUpload}
          />
          <div className="flex flex-col items-center justify-center text-white">
            <CloudUploadIcon className="h-12 w-12" />
            <Typography className="mt-2 text-center">
              {fetchedUser?.id
                ? "Add Bet slip"
                : "Please sign in to upload your bet slip"}
            </Typography>
          </div>
        </label>
        {isUploading && (
          <div className="mt-4">
            <Spinner message="Getting your bet's data..." />
          </div>
        )}
        {uploadMessage && (
          <Alert
            color={uploadError ? "red" : "green"}
            className="mt-4 text-center"
          >
            {uploadMessage}
          </Alert>
        )}
        <Typography
          variant="h6"
          color="white"
          className="text-center mt-8 mb-4 font-bold"
        >
          Once you have added your betslip media, you will have the chance to
          review it and confirm the amount of units before posting it.
        </Typography>
      </Card>
      {ocrResult?.bet_slip && (
        <BetSlipModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setOcrResult(null); // Reset the OCR result when modal is closed
          }}
          betSlip={ocrResult?.bet_slip}
        />
      )}
    </div>
  );
}

export default BetScreen;
