// PrivateRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";

const PrivateRoute = () => {
  const { fetchedUser, cognitoUser } = useAuthContext();
  return fetchedUser?.id && cognitoUser ? (
    <Outlet />
  ) : (
    <Navigate to="/landing" />
  );
};

export default PrivateRoute;
