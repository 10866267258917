import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { Button } from "@material-tailwind/react";
import { formatExtendedUSDate } from "utils/format";
import BetCard from "components/cards/BetCard";
import { getEarningsByWeek } from "utils/helper/Calculate";
import { LineChart } from "components/PerformanceChart/PerformanceChart";
import SignIn from "./SignIn";
import ProfileHeader from "./header";

interface DataItem {
  x: string; // Label, typically a date or category
  y: number; // Numeric value, e.g., earnings
}

interface EarningsChartProps {
  data: DataItem[];
}

const EarningsChart: React.FC<EarningsChartProps> = ({ data }) => {
  const chartData = useMemo(
    () => ({
      labels: data.map((item) => item.x),
      datasets: [
        {
          label: "Weekly Earnings",
          data: data.map((item) => item.y),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    }),
    [data]
  );

  if (data.length === 0) {
    return (
      <div className="text-center my-10">
        <p className="text-lg text-black">
          Looks like there are no earnings yet. Start betting to see your
          earnings grow!
        </p>
        <BetCTAButton />
      </div>
    );
  }

  return <LineChart chartData={chartData} />;
};

const BetCTAButton: React.FC = () => (
  <Link to={"/bet"}>
    <Button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Create or Upload Bet Slip
    </Button>
  </Link>
);

const ProfileScreen: React.FC = () => {
  const location = useLocation();
  const { fetchedUser } = useAuthContext();
  const [userActiveBets, setUserActiveBets] = useState<any[]>([]);
  const [userSettledBets, setUserSettledBets] = useState<any[]>([]);
  const [earningsData, setEarningsData] = useState<DataItem[]>([]);

  const getNavLinkClass = useCallback(
    (path: string) => {
      return location.pathname === path
        ? "text-white underline decoration-white decoration-2"
        : "text-white hover:underline";
    },
    [location.pathname]
  );

  useEffect(() => {
    const fetchBets = async () => {
      if (fetchedUser?.id) {
        const bets = await fetchedUser.Bets();
        const activeBets = bets.data.filter((bet) => bet.isActive);
        const settledBets = bets.data.filter((bet) => !bet.isActive);
        setUserActiveBets(activeBets);
        setUserSettledBets(settledBets);
      }
    };

    fetchBets();
  }, [fetchedUser]);

  useEffect(() => {
    if (!fetchedUser?.id) return;
    getEarningsByWeek(fetchedUser.id).then(setEarningsData);
  }, [fetchedUser?.id]);

  const getPageforRoute = useCallback(() => {
    switch (location.pathname) {
      case "/profile/settled-bets":
        return (
          <>
            {userSettledBets.length > 0 ? (
              userSettledBets.map((bet) => (
                <div className="my-2 lg:my-8 mx-4" key={bet.id}>
                  <BetCard
                    betID={bet.id}
                    userID={bet.userID}
                    username={fetchedUser?.username!}
                    handle={fetchedUser?.handle!}
                    betDate={formatExtendedUSDate(bet.createdAt ?? "")}
                    profilePictureURI={fetchedUser?.profilePicURI}
                    odds={bet.combinedOdds}
                    wager={bet.wager}
                    result={bet.result}
                    active={bet.isActive!}
                    tailing={true}
                  />
                </div>
              ))
            ) : (
              <div className="text-center my-10">
                <p className="text-lg text-black">
                  You currently have no settled bets.
                </p>
                <BetCTAButton />
              </div>
            )}
          </>
        );

      case "/profile/earnings":
        return (
          <div className="flex justify-center items-center bg-inherit py-4">
            <div className="w-full">
              <EarningsChart data={earningsData} />
            </div>
          </div>
        );

      default:
        return (
          <>
            {userActiveBets.length > 0 ? (
              userActiveBets.map((bet) => (
                <div className="my-2 lg:my-8 mx-4" key={bet.id}>
                  <BetCard
                    betID={bet.id}
                    userID={bet.userID}
                    username={fetchedUser?.username!}
                    handle={fetchedUser?.handle!}
                    betDate={formatExtendedUSDate(bet.createdAt ?? "")}
                    profilePictureURI={fetchedUser?.profilePicURI}
                    odds={bet.combinedOdds}
                    wager={bet.wager}
                    result={bet.result}
                    active={bet.isActive!}
                    tailing={true}
                  />
                </div>
              ))
            ) : (
              <div className="text-center my-10">
                <p className="text-lg text-black">
                  You currently have no active bets.
                </p>
                <BetCTAButton />
              </div>
            )}
          </>
        );
    }
  }, [location.pathname, userSettledBets, userActiveBets, fetchedUser]);

  return fetchedUser?.id ? (
    <div className="flex flex-col px-4 lg:px-14">
      <ProfileHeader />
      <div className="flex flex-row space-x-4 place-content-evenly px-5 py-2 md:py-5">
        <NavLink
          to="/profile"
          className={`text-gray-500 hover:text-gray-700 ${getNavLinkClass(
            "/profile"
          )}`}
        >
          Active Bets
        </NavLink>
        <NavLink
          to="/profile/settled-bets"
          className={`text-gray-500 hover:text-gray-700 ${getNavLinkClass(
            "/profile/settled-bets"
          )}`}
        >
          Settled Bets
        </NavLink>
        <NavLink
          to="/profile/earnings"
          className={`text-gray-500 hover:text-gray-700 ${getNavLinkClass(
            "/profile/earnings"
          )}`}
        >
          Earnings
        </NavLink>
      </div>
      <div className="flex-grow my-2 lg:my-8 h-[calc(92vh-200px)] overflow-auto pb-20">
        {getPageforRoute()}
      </div>
    </div>
  ) : (
    <SignIn />
  );
};

export default ProfileScreen;
