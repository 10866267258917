import { createTheme } from "@mui/material";
import colors from "./colors";

export const CapTailTheme = createTheme({
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.white,
        },
        colorPrimary: colors.primary,
        colorSecondary: colors.white,
      },
    },
    // Style overrides for TextField
    MuiTextField: {
      styleOverrides: {
        root: {
          // Styles applied to the root element
          "& .MuiInputBase-input": {
            color: colors.white, // text color
          },
          "& .MuiInputLabel-root": {
            // label color
            color: colors.lightgrey, // default label color
          },
          "& .MuiInputLabel-root.Mui-focused": {
            // label color when focused
            color: colors.primary, // label color when the TextField is focused
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.border, // default border color
            },
            "&:hover fieldset": {
              borderColor: colors.primary, // border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.primary, // border color when focused
            },
          },
          "& .MuiOutlinedInput-root.Mui-error": {
            "& fieldset": {
              borderColor: colors.error, // border color when error is set
            },
          },
          "& .MuiFormHelperText-root.Mui-error": {
            color: colors.error, // helper text color when error is set
          },
          "&MuiFormHelperText": {
            styleOverrides: {
              root: {
                color: "red",
              },
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors["dark-orange"],
      contrastText: colors.white,
    },
    error: {
      main: colors.error, // use the error color defined in your colors object
    },
    // ... other colors
  },
});
