// Spinner.tsx
import React from "react";
type SpinnerProps = {
  message?: string;
};
const Spinner: React.FC<SpinnerProps> = ({ message }) => {
  return (
    <div className="flex items-center justify-center space-x-2">
      <div className="w-4 h-4 rounded-full animate-spin border-2 border-white border-t-transparent"></div>
      <p className="text-white">{message ? message : "Processing..."}</p>
    </div>
  );
};

export default Spinner;
