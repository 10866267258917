import React from "react";
import MobileNavBar from "./MobileNavBar";
import DesktopNavBar from "./DesktopNavBar";

function Navbar() {
  return (
    <>
      <div className="md:hidden">
        <MobileNavBar />
      </div>
      <div className="hidden md:flex">
        <DesktopNavBar />
      </div>
    </>
  );
}

export default Navbar;
