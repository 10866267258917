import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import CloseIcon from "@mui/icons-material/Close";
import { Schema } from "../../../amplify/data/resource";
import { SportsLeague } from "../../../API";

interface EditLegModalProps {
  isOpen: boolean;
  leg: Schema["Bet"]["type"]["legs"][0] | null;
  onSave: (updatedLeg: Schema["Bet"]["type"]["legs"][0]) => void;
  onClose: () => void;
}

const EditLegModal: React.FC<EditLegModalProps> = ({
  isOpen,
  leg,
  onSave,
  onClose,
}) => {
  const [updatedLeg, setUpdatedLeg] = useState(leg);

  useEffect(() => {
    setUpdatedLeg(leg);
  }, [leg]);

  if (!updatedLeg) return null;

  const handleSave = () => {
    onSave(updatedLeg);
  };

  const handleOddsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const odds = value.startsWith("-") ? parseFloat(value) : parseFloat(value);
    setUpdatedLeg({
      ...updatedLeg,
      odds: isNaN(odds) ? 0 : odds,
    });
  };

  const setOddsPositive = () => {
    setUpdatedLeg({
      ...updatedLeg,
      odds: Math.abs(updatedLeg.odds || 0),
    });
  };

  const setOddsNegative = () => {
    setUpdatedLeg({
      ...updatedLeg,
      odds: -Math.abs(updatedLeg.odds || 0),
    });
  };

  return (
    <Dialog
      open={isOpen}
      handler={onClose}
      size="sm"
      className="bg-black rounded-lg shadow-primary shadow-xl bg-opacity-50"
    >
      <DialogHeader className="flex justify-between items-center text-white bg-dark-black rounded-t-lg p-4">
        <h6 className="text-lg">Edit Bet Leg</h6>
        <IconButton className="text-white" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogBody className="text-white max-h-[70vh] pb-20 overflow-y-auto bg-dark-gray space-y-6 rounded-b-lg p-4">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-1">
            <label className="font-bold">Team:</label>
            <input
              value={updatedLeg.team?.name || ""}
              onChange={(e) =>
                setUpdatedLeg({ ...updatedLeg, team: { name: e.target.value } })
              }
              className="text-black px-4 py-2 rounded-md shadow-primary border-2 border-primary bg-light-gray focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">Player:</label>
            <input
              value={updatedLeg.player?.name || ""}
              onChange={(e) =>
                setUpdatedLeg({
                  ...updatedLeg,
                  player: { name: e.target.value },
                })
              }
              className="text-black px-4 py-2 rounded-md shadow-primary border-2 border-primary bg-light-gray focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">Odds:</label>
            <div className="flex items-center">
              <Button
                size="sm"
                className="bg-dark-orange text-white mr-2"
                onClick={setOddsPositive}
              >
                +
              </Button>
              <input
                value={updatedLeg.odds}
                type="text"
                onChange={handleOddsChange}
                className="text-black px-4 py-2 rounded-md shadow-primary border-2 border-primary bg-light-gray focus:ring-2 focus:ring-primary focus:border-transparent"
              />
              <Button
                size="sm"
                className="bg-dark-orange text-white ml-2"
                onClick={setOddsNegative}
              >
                -
              </Button>
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">Date:</label>
            <input
              value={updatedLeg.date || ""}
              type="date"
              onChange={(e) =>
                setUpdatedLeg({ ...updatedLeg, date: e.target.value })
              }
              className="text-black px-4 py-2 rounded-md shadow-primary border-2 border-primary bg-light-gray focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">Time:</label>
            <input
              value={updatedLeg.time || ""}
              type="time"
              onChange={(e) =>
                setUpdatedLeg({ ...updatedLeg, time: e.target.value })
              }
              className="text-black px-4 py-2 rounded-md shadow-primary border-2 border-primary bg-light-gray focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">Event:</label>
            <input
              value={updatedLeg.event || ""}
              onChange={(e) =>
                setUpdatedLeg({ ...updatedLeg, event: e.target.value })
              }
              className="text-black px-4 py-2 rounded-md shadow-primary border-2 border-primary bg-light-gray focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">League:</label>
            <input
              value={updatedLeg.league || ""}
              onChange={(e) =>
                setUpdatedLeg({
                  ...updatedLeg,
                  league: e.target.value as SportsLeague,
                })
              }
              className="text-black px-4 py-2 rounded-md shadow-primary border-2 border-primary bg-light-gray focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
          <div className="flex flex-col space-y-1">
            <label className="font-bold">Type:</label>
            <input
              value={updatedLeg.type || ""}
              onChange={(e) =>
                setUpdatedLeg({ ...updatedLeg, type: e.target.value })
              }
              className="text-black px-4 py-2 rounded-md shadow-primary border-2 border-primary bg-light-gray focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
        </div>
      </DialogBody>

      <DialogFooter className="flex justify-center bg-dark-black rounded-b-lg p-4">
        <Button
          onClick={handleSave}
          className="bg-dark-orange text-white w-full py-2 rounded-md shadow-md shadow-black hover:bg-primary transition-all duration-300"
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default EditLegModal;
